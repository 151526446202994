import React from 'react';
import {
  Typography,
  Drawer,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowDownward, OpenWithRounded } from '@mui/icons-material';
import BetModifyComponent from './BetModifyComponent';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBetSlip } from '../../store/appSlice';

// Add this new styled component for the live indicator
const LiveIndicator = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: '#4CAF50',
  marginRight: 8,
  animation: 'blink 1s ease-in-out infinite',
  '@keyframes blink': {
    '0%': { opacity: 0.5 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.5 },
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    maxHeight: '80vh',
    overflowY: 'auto',
    zIndex: 1300,
    borderRadius: '16px 16px 0 0',
    boxShadow: '0 -5px 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    backdropFilter: 'blur(25px)',
    border: 'none',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const DrawerHeaderTop = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const StyledMatchDetails = styled('div')({
  marginTop: '8px',
  padding: '0px 8px',
});

const BackButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: '',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const BetSlipDrawer = ({}) => {
  
  const dispatch = useDispatch()
  const {betSlip} = useSelector((state) => state.appslice)
  const open = betSlip.length > 0
  const onClose = () => {
    dispatch(deleteBetSlip())
  }
  const marketDetails = betSlip[0]
  return (
    <StyledDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
    >
      <DrawerHeader>
        <DrawerHeaderTop>
          <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: '400' }}>
            Bet Slip
          </Typography>
          <BackButton onClick={onClose}>
            <ArrowDownward sx={{ fontSize: '24px', color: 'white' }} />
          </BackButton>
        </DrawerHeaderTop>

      </DrawerHeader>
      <StyledMatchDetails>
        <Typography variant='body2' sx={{ fontSize: '12px', fontWeight: '400', display: 'flex', alignItems: 'center', marginBottom: '4px' ,gap:1}}>
          {marketDetails?.market?.match_name || ''}
          {marketDetails?.market?.inplay && <LiveIndicator />}
        </Typography>
        <Typography variant='body2' sx={{ fontSize: '20px', fontWeight: '500' }}>
          {marketDetails?.market?.runners?.[marketDetails?.index]?.name || ''}
        </Typography>
      </StyledMatchDetails>
      <div style={{ marginTop: '4px', padding: '0px 8px 8px 8px', overflowY: 'auto' }}>
        <BetModifyComponent
        betType={marketDetails?.betType}
          marketDetails={marketDetails||[]}
          onCancel={onClose}
        />
      </div>
    </StyledDrawer>
  );
};

export default BetSlipDrawer;