import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#232234',
      text:'#e4e9f0' // Replace with your desired primary color
    },
    secondary: {
      main: '#CAD0DE', 
      // Replace with your desired secondary color
    },
    contrastColor:{
      main:'#CAD0DE',
      secondary:'#d8dee8'
    },
    layButtonColor: "#f9c9d4",
    backButtonColor: "#addbf8",
    buttonPrimaryColor:'#e1f038',
    layButtonColorBg: "rgb(239 193 179 / 93%)",
    backButtonColorBg: "rgb(179 209 239 / 93%)",
    appBarColor: "#242323",
    backgroundColor:'#E5E5E5',
    bgColor: '#212124',
    buttonHoverColor:'#CA3E47',

    buttonSecondaryColor: '#9E172F',
  },
  typography: {
    fontFamily: [
      'Helvetica Neue', 'sans-serif', 'BBAlpha Sans'
    ].join(','),
  },
});

export default theme;
