import React, { useCallback, useContext, useMemo } from 'react'
import { Grid, LinearProgress, styled, useMediaQuery } from '@mui/material'
import { TabContext } from './App'
import DashboardCarousal from '../../component/DashboardCarousal/DashboardCarousal'
import CategoryNavigation from '../../component/BottomCategoryNavigation/CategoryNavigation'
import TopHeader from '../../component/0_Common/TopHeader'
import { Outlet } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import Footer from '../0_Common/Footer'
import { useWebSocketSelector } from '../../hooks/useWebsocket'
import { useSelector } from 'react-redux'
import BetSlipDrawer from '../0_Common/BetSlipDrawer'
import BetSlipAccordion from '../0_Common/BetSlipAccordion'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/appSlice'

const PaddingGrid = styled(Grid)({ padding: '0' })

const MemoizedTopHeader = TopHeader
const MemoizedSideBarLeft = React.memo(SideBarLeft)
const MemoizedDashboardCarousal = React.memo(DashboardCarousal)
const MemoizedFooter = React.memo(Footer)
const MemoizedCategoryNavigation = React.memo(CategoryNavigation)

export const RootLayout = React.memo(() => {
  const theme = useTheme()
  const isDesktopOrTablet = useMediaQuery(theme.breakpoints.up('md'))
  const status = useWebSocketSelector((state) => state.websocket.status)
  const { currentTab, setCurrentTab } = useContext(TabContext)
  const dispatch = useDispatch()

  const handleContentClick = useCallback(() => {
    if (!sessionStorage.getItem('userDetails')) {
      dispatch(openModal('login'))
    }
  }, [dispatch])

  const isLoading = JSON.parse(sessionStorage.getItem('userDetails') || '{}')?.token 
                  ? status === 'CONNECTING' 
                  : !sessionStorage.getItem('matchesFetched')
  const hasError = status === 'ERROR' || status === 'CLOSED'

  const updateTab = useCallback(
    (newValue) => {
      setCurrentTab(newValue)
    },
    [setCurrentTab],
  )
  const { betSlip } = useSelector(state => state.appslice)

  const mobileLayout = useMemo(() => (
    <>
      <div>
        <MemoizedTopHeader />
      </div>
      <PaddingGrid container spacing={2}>
        <Grid item xs={12} onClick={handleContentClick}>
          <div style={{ margin: '4px 4px 0px 4px',minHeight: '80vh' }}>
            <Outlet />
          </div>
          <MemoizedFooter />
        </Grid>
      </PaddingGrid>
      <Grid item xs={12}>
        <MemoizedCategoryNavigation currentTab={currentTab} updateTab={updateTab} />
      </Grid>
      <BetSlipDrawer />
    </>
  ), [currentTab, updateTab, handleContentClick])

  const desktopAndTabletLayout = useMemo(() => (
    <>
      <MemoizedTopHeader />
      <PaddingGrid container spacing={1}>
        <Grid item md={3} lg={2.5}>
          <MemoizedSideBarLeft />
        </Grid>
        <Grid item md={6} lg={6.5} sx={{ marginTop: '10px', padding: '0 4px 0px 4px' }} onClick={handleContentClick}>
          <Outlet />
         
        </Grid>
        <Grid item md={3} lg={3} sx={{ marginTop: '10px' }}>
          <BetSlipAccordion />
        </Grid>
<Grid item xs={2.5}>
</Grid>

        <Grid item xs={9.5}>
          <MemoizedFooter />
        </Grid>
      </PaddingGrid>

    </>
  ), [handleContentClick]);


  return (
    <>
      {isLoading && (
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      {hasError && (
        <div>Connection error. Please check your internet connection.</div>
      )}
      {!isDesktopOrTablet ? mobileLayout : desktopAndTabletLayout}
    </>
  )
})