import * as React from "react";
import Grid from "@mui/material/Grid";
import EventCard from "../0_Common/EventCard";
import { Divider, Typography } from "@mui/material";

const CasinoGrid = ({ data }) => {
  return (
    <div>
      {" "}
      <Typography fontSize={20} style={{ fontWeight: 600 }}>
        Casino
      </Typography>
      <Divider style={{ margin: 20 }} />
      <Grid container spacing={2}>
        {data.map((item) => (
          <Grid item xs={6} lg={4} key={item.id}>
            <EventCard item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CasinoGrid;
