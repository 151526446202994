import React from "react";
import Slider from "react-slick";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  width: "100%",
  position: "relative",
  touchAction: "pan-y",
  marginTop: '10px',
}));

const Slide = styled(Box)(({ theme }) => ({
  flex: "0 0 100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "none",
}));

const ShinyCard = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  maxWidth: "95%",
  maxHeight: "auto",
  borderRadius: theme.shape.borderRadius * 2,
  marginBottom: '10px',
  cursor: 'pointer',
  // boxShadow: theme.shadows[10],
  '&::before': {
    content: '""',
    position: "absolute",
    top: -50,
    left: -50,
    width: "200%",
    height: "200%",
    background: "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)",
    transform: "rotate(30deg)",
    transition: "0.5s",
    animation: "moveShine 3s infinite linear",
  },
  '@keyframes moveShine': {
    '0%': {
      transform: 'translateX(-100%) rotate(30deg)',
    },
    '100%': {
      transform: 'translateX(100%) rotate(30deg)',
    },
  },
  '&:hover::before': {
    animation: "none",
    left: "125%",
  },
}));

const StyledImg = styled('img')({
  width: "100%",
  height: "auto",
  display: "block",
});

const DesktopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(2),
}));

const DesktopImage = styled(Box)(({ theme }) => ({
  width: "calc(50% - 16px)",
  maxWidth: "600px",
  borderRadius: theme.shape.borderRadius * 2,
  overflow: "hidden",
  cursor: "pointer",
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  '.slick-dots': {
    bottom: '10px',
  },
  '.slick-dots li button:before': {
    color: theme.palette.primary.main,
  },
  '.slick-track': {
    padding: '8px'
  }
}));

const DashboardCarousal = () => {
  const slides = [
    { id: 1, img: 'banner.webp' },
    { id: 2, img: 'banner2.webp' },
  ];

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  if (!slides || slides.length === 0) return null;

  if (isDesktop) {
    return (
      <DesktopContainer>
        {slides.map((slide, index) => (
          <DesktopImage key={index}>
            <StyledImg
              src={`${slide.img}`}
              alt={`Banner ${slide.id}`}
            />
          </DesktopImage>
        ))}
      </DesktopContainer>
    );
  }

  return (
    <CarouselContainer sx={{ margin: "0px" }}>
      <StyledSlider {...settings}>
        {slides.map((slide, index) => (
          <Slide key={index}>
            <ShinyCard>
              <StyledImg
                src={`${slide.img}`}
                alt={`Banner ${slide.id}`}
              />
            </ShinyCard>
          </Slide>
        ))}
      </StyledSlider>
    </CarouselContainer>
  );
};

export default DashboardCarousal;