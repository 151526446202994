import { Box, Button, Modal, Typography, Checkbox, FormControlLabel } from "@mui/material";
import theme from "../../utils/customTheme";
import { useState } from "react";

export const BetLimitRuleModal = ({ open, onClose }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Multiple Account & Bet Limit Rule Acceptance
          </Typography>
          <Typography variant="body2" gutterBottom>
            Members are not permitted to hold multiple accounts. This includes holding an account with any associated site operating on the same platform as this site.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Where maximum bet or maximum market limits are imposed, the Site reserves to the right to void bets from accounts found to be exceeding these limits by using multiple accounts across this and any other associated sites.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please confirm your acceptance and understanding of this rule.
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="I have read and accept the rules"
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={onClose}
            disabled={!isChecked}
            sx={{ mt: 2, background: theme.palette.buttonSecondaryColor }}
          >
            Accept and Continue
          </Button>
        </Box>
      </Modal>
    );
  };