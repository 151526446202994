
import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Modal,
  IconButton,
  Tabs,
  Tab,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { CreateDepositRequestUrl, ParentListUrl, UserAccountListUrl, UserAccountCreateUrl, WithdrawalRequestUrl, UserAccountDeleteUrl } from '../../constants';
import { SYMBOL } from '../../utils/const';
import MuiAlert from '@mui/material/Alert';
import useApiFetch from '../../hooks/useApiFetch';
import { useSnackbar } from '../../hooks/useSnackbar'; // Assuming you have a custom snackbar hook

const WalletContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  '& .MuiToggleButton-root': {
    width: '50%',
    color: theme.palette.buttonSecondaryColor,
    '&.Mui-selected': {
      backgroundColor: theme.palette.buttonSecondaryColor,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));


const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const StyledCardMedia = styled(CardMedia)({
  height: 60,
  width: '80%',
  backgroundSize: 'contain',
  margin: '10px 0',
});

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 500,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const DEPOSIT_METHODS = [
  { name: 'Bank Transfer', image: 'https://st.softgamings.com/uploads/BankTransfer_logo_1200x600.png', bankId: 1 },
  { name: 'Paytm', image: 'https://miro.medium.com/v2/resize:fill:320:214/1*04xD0gseEr_g4wA7NLQ5iw.png', bankId: 2 },
  { name: 'PhonePe', image: 'https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2023/02/14/227260-phonepe1.png?itok=MW54Zukx', bankId: 3 },
  { name: 'GPay', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq_S1I-kRo8EmqBe0MSfTSRdtAflozeeDoBQ&s', bankId: 4 },
  { name: 'UPI', image: 'https://m.economictimes.com/thumb/msid-74960608,width-1200,height-900,resizemode-4,imgsize-49172/upi-twitter.jpg', bankId: 5 },
];

const Alert = React.forwardRef(function Alert(
  props,  
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BalanceBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const BankDetailsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const WalletPage = () => {
  const [view, setView] = useState('deposit');
  const [amount, setAmount] = useState('');
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [utrId, setUtrId] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [parentListData, setParentListData] = useState(null);
  const [parentListLoading, setParentListLoading] = useState(false);
  const [parentListError, setParentListError] = useState(null);
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState({
    accountNumber: '',
    confirmAccountNumber: '',
    ifscCode: '',
    accountHolder: '',
    bankName: '',
    branchName: '',
  });
  const [selectedAccount, setSelectedAccount] = useState(null);

  const authToken = userDetails.token;
  const { showSnackbar } = useSnackbar();

  const { data: userAccounts, loading: userAccountsLoading, error: userAccountsError, fetchData: fetchUserAccounts } = useApiFetch(UserAccountListUrl, {
    headers: {
      'accept': 'application/json, text/plain, */*',
      'authorization': authToken,
      'content-type': 'application/json'
    },
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({
      "bank_id": 1,
      "page": 1,
      "items": 50,
      "total_items": 1
  })
  });

  useEffect(() => {
    fetchUserAccounts();
  }, []);

  useEffect(()=>{
    setParentListData(userAccounts)
  },[userAccounts])

  useEffect(() => {
    if (selectedMethod !== null) {
      getParentList(selectedMethod);
    }
  }, [selectedMethod]);

  // useEffect(() => {
  //   fetchUserAccounts();

  //   // Set up an interval to fetch site settings every 5 seconds
  //   const intervalId = setInterval(() => {
  //     fetchUserAccounts();
  //   }, 5000);

  //   Clean up the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);

  const getParentList = async (bankId) => {
    setParentListLoading(true);
    setParentListError(null);

    try {
      const response = await fetch(ParentListUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken,
        },
        body: JSON.stringify({ bank_id: bankId }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch parent list');
      }

      const data = await response.json();
      setParentListData(data);
    } catch (error) {
      setParentListError(error.message);
    } finally {
      setParentListLoading(false);
    }
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleMethodSelect = (bankId) => {
    setSelectedMethod(bankId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedMethod(null);
    setSelectedTab(0);
    setAmount('');
    setUtrId('');
    setScreenshot(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUtrIdChange = (event) => {
    setUtrId(event.target.value);
  };

  const handleScreenshotChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setScreenshot(event.target.files[0]);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };


  const handleSubmit = async () => {
    if (!selectedMethod || !amount || !utrId || !screenshot) {
      showSnackbar('Please fill all required fields', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('type', '1');
    formData.append('amount', amount);
    formData.append('account_id', parentListData.data[selectedTab].id.toString());
    formData.append('bank_id', selectedMethod.toString());
    formData.append('offer_id', '0');
    formData.append('utr', utrId);
    formData.append('image', screenshot);

    setIsLoading(true);

    try {
      const response = await fetch(CreateDepositRequestUrl, {
        method: 'POST',
        headers: {
          'Authorization': authToken,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Deposit request failed');
      }

      const data = await response.json();
      showSnackbar('Deposit request submitted successfully', 'success');
  
      handleCloseModal();
    } catch (error) {
      showSnackbar('Error submitting deposit request', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccountModalOpen = () => {
    setIsAccountModalOpen(true);
  };

  const handleAccountModalClose = () => {
    setIsAccountModalOpen(false);
  };

  const handleAccountDetailsChange = (event) => {
    const { name, value } = event.target;
    setAccountDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAccountDetailsSubmit = async () => {
    if (!accountDetails.accountNumber || !accountDetails.confirmAccountNumber || 
        !accountDetails.ifscCode || !accountDetails.accountHolder || 
        !accountDetails.bankName || !accountDetails.branchName) {
      showSnackbar('Please fill all fields', 'error');
      return;
    }

    if (accountDetails.accountNumber !== accountDetails.confirmAccountNumber) {
      showSnackbar('Account numbers do not match', 'error');
      return;
    }

    try {
      const response = await fetch(UserAccountCreateUrl, {
        method: "POST",
        headers: {
          "accept": "application/json, text/plain, */*",
          "authorization": authToken,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          id: null,
          account_number: accountDetails.accountNumber,
          confirm_account_number: accountDetails.confirmAccountNumber,
          ifsc: accountDetails.ifscCode,
          account_holder: accountDetails.accountHolder,
          bank_name: accountDetails.bankName,
          branch_name: accountDetails.branchName,
          bank_id: 1 // Assuming bank_id is always 1 for now
        }),
        mode: "cors",
        credentials: "include"
      });

      if (!response.ok) {
        throw new Error('Failed to submit account details');
      }

      const data = await response.json();
      showSnackbar('Account details submitted successfully', 'success');
      fetchUserAccounts();
      handleAccountModalClose();
    } catch (error) {
      console.error('Error submitting account details:', error);
      showSnackbar('Error submitting account details', 'error');
    }
  };

  const handleDeleteAccount = async (accountId) => {
    try {
      const response = await fetch(UserAccountDeleteUrl, {
        method: "POST",
        headers: {
          "accept": "application/json, text/plain, */*",
          "authorization": authToken,
          "content-type": "application/json",
        },
        body: JSON.stringify({ id: accountId }),
        mode: "cors",
        credentials: "include"
      });

      if (!response.ok) {
        throw new Error('Failed to delete account');
      }

      showSnackbar('Account deleted successfully', 'success');
      fetchUserAccounts(); // Refresh the account list
    } catch (error) {
      console.error('Error deleting account:', error);
      showSnackbar('Error deleting account', 'error');
    }
  };

  const renderDepositMethods = () => (
    <Grid container spacing={2}>
      {DEPOSIT_METHODS.map((method) => (
        <Grid item xs={6} sm={4} md={3} key={method.bankId}>
          <StyledCard onClick={() => handleMethodSelect(method.bankId)}>
            <StyledCardMedia
              image={method.image}
              title={method.name}
            />
            <CardContent>
              <Typography variant="body2" align="center">
                {method.name}
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );

  const renderDepositForm = () => {
    if (parentListData?.data?.length > 0) {
      return (
        <Box>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {parentListData?.data.map((account, index) => (
              <Tab key={account.id} label={account.account_holder} />
            ))}
          </Tabs>
          {parentListData?.data.map((account, index) => (
            <TabPanel key={account.id} value={selectedTab} index={index}>
              <Typography variant="h6" gutterBottom>
                Enter The Deposit Amount
              </Typography>
              <TextField
                fullWidth
                label="Amount"
                variant="outlined"
                type="number"
                value={amount}
                onChange={handleAmountChange}
                sx={{ mt: 2 }}
                InputProps={{
                  startAdornment: <Typography>{SYMBOL}</Typography>,
                }}
              />
              <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
                Amount of one deposit (from ₹100 to ₹1000000)
              </Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                {[500, 1000, 5000, 10000].map((value) => (
                  <Grid item key={value}>
                    <Button variant="contained" onClick={() => setAmount(value.toString())}>
                      ₹{value}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <TextField
                fullWidth
                label="UTR ID"
                variant="outlined"
                value={utrId}
                onChange={handleUtrIdChange}
                sx={{ mt: 2 }}
              />
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="screenshot-upload"
                type="file"
                onChange={handleScreenshotChange}
              />
              <label htmlFor="screenshot-upload">
                <Button variant="contained" component="span" sx={{ mt: 2 }}>
                  Upload Screenshot
                </Button>
              </label>
              {screenshot && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  File selected: {screenshot.name}
                </Typography>
              )}
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Deposit'}
              </Button>
            </TabPanel>
          ))}
        </Box>
      );
    } else {
      return (
        <Box display="flex" flexDirection="column" alignItems="center" py={4}>
          <SearchIcon sx={{ fontSize: 64, color: 'text.secondary' }} />
          <Typography variant="h6" color="textSecondary" mt={2}>
            No Deposit Methods found
          </Typography>
        </Box>
      );
    }
  }

  // Memoize the accounts data
  const memoizedAccounts = useMemo(() => {
    return userAccounts?.data?.data || [];
  }, [userAccounts]);

  const renderBankDetails = () => {
    if (userAccountsLoading) {
      return <Typography>Loading user account details...</Typography>;
    }

    if (userAccountsError) {
      return <Typography color="error">Error loading user account details</Typography>;
    }

    if (memoizedAccounts.length === 0) {
      return <Typography>No user account details available</Typography>;
    }

    const handleSubmit = async () => {
      if (!selectedAccount || !withdrawAmount) {
        showSnackbar('Please select an account and enter an amount', 'error');
        return;
      }

      try {
        const response = await fetch(WithdrawalRequestUrl, {
          method: "POST",
          headers: {
            "accept": "application/json, text/plain, */*",
            "authorization": authToken,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            type: "2",
            amount: Number(withdrawAmount),
            account_id: selectedAccount + "",
            bank_id: 1
          }),
          mode: "cors",
          credentials: "include"
        });

        if (!response.ok) {
          throw new Error('Withdrawal request failed');
        }

        const data = await response.json();

        showSnackbar('Withdrawal request submitted successfully', 'success');
        setSelectedAccount(null);
        setWithdrawAmount('');
      } catch (error) {
        console.error('Error submitting withdrawal request:', error);
        showSnackbar('Error submitting withdrawal request', 'error');
      }
    };

    return (
      <>
        <Typography variant="h6" gutterBottom>User Account List</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Account Holder</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>IFSC</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Branch Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memoizedAccounts.map((account) => (
                <TableRow key={account.id}>
                  <TableCell>
                    <Radio
                      checked={selectedAccount === account.id}
                      onChange={() => setSelectedAccount(account.id)}
                    />
                  </TableCell>
                  <TableCell>{account.account_holder}</TableCell>
                  <TableCell>{account.account_number}</TableCell>
                  <TableCell>{account.ifsc}</TableCell>
                  <TableCell>{account.bank_name}</TableCell>
                  <TableCell>{account.branch_name}</TableCell>
                  <TableCell>{new Date(account.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteAccount(account.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TextField
          fullWidth
          margin="normal"
          label="Withdrawal Amount"
          type="number"
          value={withdrawAmount}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          InputProps={{
            startAdornment: <Typography>₹</Typography>,
          }}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selectedAccount || !withdrawAmount}
          sx={{ mt: 2 }}
        >
          Submit Withdrawal Request
        </Button>
      </>
    );
  };
  
  const renderWithDraw = () => {
    return (
      <>
        <BalanceBox>
          <Typography variant="h6" gutterBottom>Account Balance</Typography>
          {/* <Typography variant="body1">Total Balance: ₹{userDetails.total_balance}</Typography> */}
          <Typography variant="body1">Available Balance: ₹{userDetails.balance}</Typography>  
        </BalanceBox>

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard onClick={handleAccountModalOpen}>
              <StyledCardMedia
                image="https://st.softgamings.com/uploads/BankTransfer_logo_1200x600.png"
                title="Bank Transfer"
              />
              <CardContent>
                <Typography variant="body2" align="center">
                  Bank Transfer
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        </Grid>

        <BankDetailsBox>
          <Typography variant="h6" gutterBottom>Bank Details</Typography>
          {renderBankDetails()}
        </BankDetailsBox>
      </>
    );
  };

  
  const handleWithdrawSubmit = () => {
    // Handle withdrawal submission
  };

  const renderAccountDetailsModal = () => (
    <Modal open={isAccountModalOpen} onClose={handleAccountModalClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Account Detail
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          name="accountNumber"
          label="Account Number"
          value={accountDetails.accountNumber}
          onChange={handleAccountDetailsChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="confirmAccountNumber"
          label="Confirm Account Number"
          value={accountDetails.confirmAccountNumber}
          onChange={handleAccountDetailsChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="ifscCode"
          label="IFSC Code"
          value={accountDetails.ifscCode}
          onChange={handleAccountDetailsChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="accountHolder"
          label="Account Holder"
          value={accountDetails.accountHolder}
          onChange={handleAccountDetailsChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="bankName"
          label="Bank Name"
          value={accountDetails.bankName}
          onChange={handleAccountDetailsChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="branchName"
          label="Branch Name"
          value={accountDetails.branchName}
          onChange={handleAccountDetailsChange}
        />
        <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 2, mb: 2 }}>
          Please ensure that your account holder name is as per the passbook.
          In case of mismatch, withdrawals may get stuck for upto 7 days till it
          gets resolved.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          onClick={handleAccountDetailsSubmit}
          sx={{ mt: 2 }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );

  return (
    <WalletContainer>
      <StyledPaper>
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="deposit or withdrawal"
        >
          <ToggleButton value="deposit" aria-label="deposit">
            Deposit
          </ToggleButton>
          <ToggleButton value="withdrawal" aria-label="withdrawal">
            Withdrawal
          </ToggleButton>
        </StyledToggleButtonGroup>

        {view === 'deposit' ? renderDepositMethods() : renderWithDraw()}
      </StyledPaper>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
          {parentListLoading ? (
            <Typography>Loading...</Typography>
          ) : parentListError ? (
            <Typography color="error">Error loading account details</Typography>
          ) : (
            renderDepositForm()
          )}
        </ModalContent>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {renderAccountDetailsModal()}
    </WalletContainer>
  );
};


export default WalletPage;



