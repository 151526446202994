import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import {
  connect,
  setIsConnected,
  setWebSocketData,
  updateMarketIds,
  updateGameOdds,
} from './../store/websocketSlice'
import { debounce } from 'lodash'
import { balanceURL } from '../constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { demoUserData } from '../utils/const'
import { ActiveMatches } from '../constants'

const WebSocketContext = createContext(null)

const WebSocketProviderComponent = ({ children }) => {
  const dispatch = useDispatch()
  const [marketsIdsArray, setMarketsIdsArray] = useState([])
  const [fancyValues, setFancyArray] = useState([])
  const [marketOdds, setMarketOdds] = useState({})
  const [bookmakerOdds, setBookmakerOdds] = useState({})
  const [updatedMarketDetails, setUpdatedMarketDetails] = useState({})

  const { isConnected } = useSelector((state) => state.websocket)
  const [socket, setSocket] = useState(null)
  const navigate = useNavigate();
  const location = useLocation();

  //function to fetch balance
  const fetchBalance = useCallback(async () => {
    try {
      const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}')
      const token = userDetails.token

      // Do not check for balance if token is not there
      if (!token) {
        return
      }

      const response = await fetch(balanceURL, {
        headers: {
          "accept": "application/json, text/plain, */*",
          "authorization": token,
          "content-type": "application/json"
        },
        body: JSON.stringify({ name: "kkk" }),
        method: "POST",
        mode: "cors",
        credentials: "include"
      });
      if (response.status === 401) {
        sessionStorage.removeItem('userDetails');
        sessionStorage.removeItem('token');

        if (location.pathname !== '/') {
          navigate('/', { state: { openLoginModal: true } })
        }
        window.location.reload();
        // Check if page has been reloaded before

      }
      if (!response.ok) {
        throw new Error('Failed to fetch balance');
      }


      const data = await response.json();

      // Update userDetails in sessionStorage with new balance
      const updatedUserDetails = { ...userDetails, ...data?.data?.[0] };
      sessionStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
    //eslint-disable-next-line
  }, [navigate]);

  // Set up interval to fetch balance
  useEffect(() => {
    if (sessionStorage.getItem('userDetails')) {
      const balanceInterval = setInterval(fetchBalance, 2000); // Fetch every 2 seconds
      return () => {
        clearInterval(balanceInterval);
      };
    }
  }, [fetchBalance]);

  const parseMarketIds = (marketValues) => {
    setMarketsIdsArray(prev => {
      const marketIdx = prev.findIndex(i => i.market_id === marketValues.market_id);
      if (marketIdx === -1) {
        return [...prev, marketValues]
      } else {
        return prev.map((item, index) =>
          index === marketIdx ? { ...item, ...marketValues } : item
        );
      }
    })
  }

  const parseFancyValues = (fancyValues) => {
    setFancyArray(prev => {
      let updatedArray;
      const fancyIdx = prev.findIndex(i => i.id === fancyValues.id);
  
      if (fancyIdx === -1) {
        updatedArray = [...prev, fancyValues];
      } else {
        updatedArray = prev.map((item, index) =>
          index === fancyIdx ? { ...item, ...fancyValues } : item
        );
      }
  
      // Sort the updated array based on sort_priority
      return updatedArray.sort((a, b) => {
        // Convert to number and use 0 as default if sort_priority is undefined
        const priorityA = Number(a.sort_priority) || 0;
        const priorityB = Number(b.sort_priority) || 0;
        return priorityA - priorityB;
      });
    });
  }

  const debouncedUpdateMarketIds = useMemo(
    () => debounce((data) => dispatch(updateMarketIds(data)), 500),
    [dispatch],
  )

  const debouncedUpdateGameOdds = useCallback(
    (data) => dispatch(updateGameOdds(data)),
    [dispatch]
  )

  const debouncedSetWebSocketData = useMemo(
    () => debounce((data) => dispatch(setWebSocketData(data)), 500),
    [dispatch],
  )

  const connectWebSocket = useCallback(() => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}')
    const token = userDetails.token || demoUserData.token
    if (token && !isConnected) {
      const url = window.ENV_CONFIG?.REACT_APP_WEBSOCKET_HOST || process.env.REACT_APP_WEBSOCKET_HOST;
      const newSocket = io(`${url}`, {
        path: '/api/socket',
        transports: ['websocket'],
        upgrade: false,
        query: { token },
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 10000,
        timeout: 2000000,
      })

      newSocket.on('connect', () => {
        dispatch(setIsConnected(true))
        newSocket.emit('event', {
          key: 'match-with-match_odds',
          action: 'all',
          param: {
            isActive: '1',
            sportId: '',
          },
          token,
        })
      })

      newSocket.on('disconnect', () => {
        dispatch(setIsConnected(false))
      })

      newSocket.on('error', (error) => {
        dispatch(setIsConnected(false))
      })

      newSocket.on('message', (message) => {
        const { key, data } = message
        if (key === 'match') {
          debouncedUpdateMarketIds(data)
        } else if (key === 'fancy') {
          parseFancyValues(JSON.parse(data || '{}'))
        } else if (key === 'odds') {
          const parsedData = JSON.parse(data || '{}')
          parseMarketIds(parsedData)

          setBookmakerOdds(prevOdds => ({
            ...prevOdds,
            [parsedData.market_id]: parsedData
          }))
          setMarketOdds(parsedData)
        } else if (key === 'market') {
          setUpdatedMarketDetails(data)
        }
        debouncedSetWebSocketData(data)
      })

      setSocket(newSocket)

      dispatch(connect())
    }
    //eslint-disable-next-line
  }, [
    dispatch,
    isConnected,
    setIsConnected,
    debouncedUpdateMarketIds,
    debouncedUpdateGameOdds,
    debouncedSetWebSocketData,
  ])

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
    //eslint-disable-next-line
  }, [connectWebSocket]);

  const subscribeToMarket = useCallback(
    (marketId, matchId, isEventScreen) => {
      if (socket) {
        const userDetails = JSON.parse(
          sessionStorage.getItem('userDetails') || '{}',
        )
        const token = userDetails.token

        if (isEventScreen) {
          socket.emit('event', {
            action: 'all',
            key: 'market',
            param: {
              matchId,
            },
            token,
          })

          socket.emit('event', {
            action: 'all',
            key: 'bet',
            param: {
              isActive: "1",
              matchId,
            },
            token,
          })

          // Handle 'market' key message
          socket.on('message', (message) => {
            if (message.key === 'market') {
              const selectedGameMarketIds = message.data?.markets?.map(i => i.market_id);
              sessionStorage.setItem('selectedGameMarketIds', JSON.stringify(selectedGameMarketIds));
              if (selectedGameMarketIds && selectedGameMarketIds.length > 0) {
                socket.emit('event', {
                  action: 'subscribe',
                  key: 'subscribe',
                  param: {
                    ids: selectedGameMarketIds,
                  },
                  token,
                })
              }
            }
          })
        } else {
          socket.emit('event', {
            action: 'subscribe',
            key: 'subscribe',
            param: {
              ids: [...marketId],
            },
            token,
          })
        }
      }
    },
    [socket],
  )

  const unSubscribeToMarket = useCallback(() => {
    if (socket) {
      const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
      const token = userDetails.token;
      const selectedGameMarketIds = JSON.parse(sessionStorage.getItem('selectedGameMarketIds') || '[]');
  
      // Clear all relevant state
      setMarketsIdsArray([]);
      setFancyArray([]);
      setMarketOdds({});
      setBookmakerOdds({});
      setUpdatedMarketDetails({});
  
      // Unsubscribe from all markets
      socket.emit('event', {
        action: 'unsubscribe',
        key: 'subscribe',
        param: {
          ids: selectedGameMarketIds,
        },
        token,
      });
  
      // Clear the selectedGameMarketIds from sessionStorage
      sessionStorage.setItem('selectedGameMarketIds', JSON.stringify([]));
  
      // Remove all listeners for 'message' events
      // socket.removeAllListeners('message');
    }
  }, [socket]);

  const sendMessage = useCallback(
    (eventName, data) => {
      if (socket) {
        socket.emit(eventName, data)
      }
    },
    [socket],
  )

  const fetchActiveMatches = useCallback(async () => {
    try {
      sessionStorage.setItem('matchesFetched', false);
      const response = await fetch(ActiveMatches);
      if (!response.ok) {
        throw new Error('Failed to fetch active matches');
      }
      const data = await response.json();
      sessionStorage.setItem('matcheFetched', true);
      sessionStorage.setItem('matches', JSON.stringify(data.data));
      dispatch(updateMarketIds(data.data));
    } catch (error) {
      console.error('Error fetching active matches:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    // if (!JSON.parse(sessionStorage.getItem('userDetails') || '{}').token) {
      fetchActiveMatches();
    // }
  }, [fetchActiveMatches]);

  const value = useMemo(
    () => ({
      sendMessage,
      isConnected,
      connect: connectWebSocket,
      subscribeToMarket,
      unSubscribeToMarket,
      marketsIdsArray,
      fancyValues,
      marketOdds,
      bookmakerOdds,
      updatedMarketDetails,
    }),
    [marketsIdsArray,
      sendMessage,
      updatedMarketDetails,
      isConnected,
      connectWebSocket,
      subscribeToMarket,
      unSubscribeToMarket,
      fancyValues,
      marketOdds,
      bookmakerOdds
    ],
  )

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  )
}

export const WebSocketProvider = React.memo(WebSocketProviderComponent)

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext)
  if (context === null) {
    throw new Error(
      'useWebSocketContext must be used within a WebSocketProvider',
    )
  }
  return context
}

export const useWebSocketSelector = (selector) => {
  return useSelector(
    selector,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  )
}

export const useReconnectWebsocket = () => {
  const { connect, isConnected } = useWebSocketContext();
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 5;
  const retryInterval = 5000; // 5 seconds

  useEffect(() => {
    let reconnectTimer;

    if (!isConnected && retryCount < maxRetries) {
      reconnectTimer = setTimeout(() => {
        connect();
        setRetryCount(prevCount => prevCount + 1);
      }, retryInterval);
    }

    return () => {
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
    };
  }, [isConnected, retryCount, connect]);

  useEffect(() => {
    if (isConnected) {
      setRetryCount(0);
    }
  }, [isConnected]);
};