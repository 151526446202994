import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Chip,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { AccountStatementUrl } from '../../constants';

// Styled components
const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  minHeight: '100vh',
  backgroundColor: theme.palette.grey[100],
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const PnlText = styled(Typography)(({ theme, pnl }) => ({
  color: pnl >= 0 ? theme.palette.success.main : theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
}));

const AccountStatementPage = () => {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openingBalance, setOpeningBalance] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    setError('');

    try {
      const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
      const token = userDetails?.token || '';

      const currentEndDate = endDate || new Date().toISOString().split('T')[0];
      const currentStartDate = startDate || new Date(new Date(currentEndDate) - 10 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

      const response = await fetch(AccountStatementUrl, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'authorization': token,
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          start_date: currentStartDate,
          end_date: currentEndDate,
          statement_type: "0",
          type: "",
          user_name: "",
          user_id: userDetails?.userId || 0,
          page: 1,
          items: 50,
          total_items: 0
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      setFilteredData(responseData.data.data || []);
      setOpeningBalance(responseData.data.opning_balance || 0);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch account statement data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const handleOpenDetail = (item) => {
    navigate(`/account-statement/${item.id}`, { state: { itemData: item } });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const getStatementTypeLabel = (type) => {
    const types = {
      1: 'Free Chips',
      2: 'Bet Settlement',
      14: 'Withdrawal',
      // Add more types as needed
    };
    return types[type] || 'Other';
  };

  const getCommissionColor = (commission) => {
    if (commission.startsWith('+')) return 'success.main';
    if (commission.startsWith('-')) return 'error.main';
    return 'text.primary';
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <StyledBox>
      <Header>
        <Typography variant="h5">Account Statement</Typography>
      </Header>
      <Button
        variant="outlined"
        startIcon={<FilterListIcon />}
        onClick={() => setOpenFilter(true)}
        sx={{ marginBottom: 2 }}
      >
        Filter
      </Button>
      <Typography variant="subtitle1" gutterBottom>
        Opening Balance: {openingBalance.toFixed(2)}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date & Time</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Commission</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <TableRow key={item.id} hover onClick={() => handleOpenDetail(item)}>
                <TableCell>
                  {formatDate(item.created_at)} {formatTime(item.created_at)}
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>
                  <Chip 
                    label={getStatementTypeLabel(item.statement_type)} 
                    color={item.statement_type === 2 ? 'primary' : 'default'}
                  />
                </TableCell>
                <TableCell>
                  <PnlText pnl={parseFloat(item.amount)}>
                    {parseFloat(item.amount) >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    {Math.abs(parseFloat(item.amount)).toFixed(2)}
                  </PnlText>
                </TableCell>
                <TableCell>
                  <Typography color={getCommissionColor(item.commission)}>
                    {item.commission !== '0' && item.commission}
                  </Typography>
                </TableCell>
                <TableCell>{parseFloat(item.balance).toFixed(2)}</TableCell>
                <TableCell>
                  {item.match_name && (
                    <>
                      <Typography variant="body2">{item.match_name}</Typography>
                      <Typography variant="body2" color="textSecondary">{item.market_name}</Typography>
                    </>
                  )}
                  {item.result && <Typography variant="body2">Result: {item.result}</Typography>}
                  {parseFloat(item.commission) !== 0 && (
                    <Typography variant="body2">Commission: {parseFloat(item.commission).toFixed(2)}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Dialog open={openFilter} onClose={() => setOpenFilter(false)}>
        <DialogTitle>
          Filter Account Statement
          <IconButton
            aria-label="close"
            onClick={() => setOpenFilter(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFilter(false)}>Cancel</Button>
          <Button onClick={fetchData} color="primary">Apply</Button>
        </DialogActions>
      </Dialog>
    </StyledBox>
  );
};

export default AccountStatementPage;