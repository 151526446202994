import React from 'react'
import { styled, Typography, Box, Link } from '@mui/material'
import Logo from './Logo'
import InstagramIcon from '@mui/icons-material/Instagram'
import TelegramIcon from '@mui/icons-material/Telegram'
import YouTubeIcon from '@mui/icons-material/YouTube'

const FooterDiv = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.bgColor,
  color: 'white',
  padding: '20px 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const TopBar = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
})

const NavLinks = styled(Box)({
  display: 'flex',
  gap: '20px',
})

const SocialIcons = styled(Box)({
  display: 'flex',
  gap: '10px',
})

const SecureBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  backgroundColor: 'transparent',
  padding: '5px 10px',
  borderRadius: '5px',
  marginBottom: '20px',
})

const CopyrightBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '20px',
})

function Footer() {
  return (
    <FooterDiv>
      <TopBar>
        <Logo size="2rem" />

        <Typography variant="h6">24X7 Support</Typography>
      </TopBar>

      <SecureBox>
        <img
          src="https://wver.sprintstaticdata.com/v3/static/front/img/ssl.png"
          alt="Secure"
          width="100"
          height="50"
        />
        <Typography variant="body2">
          100% SAFE Protected connection and encrypted data.
        </Typography>
      </SecureBox>

      <Typography
        variant="body2"
        sx={{ textAlign: 'center', maxWidth: '800px', marginBottom: '20px' }}
      >
        {process.env.REACT_APP_NAME} is a limited liability company incorporated under the laws of
        Curacao. Players are advised not to contact any unauthorized sources for
        {process.env.REACT_APP_NAME} accounts as this is an online platform where users can only
        register independently without intermediaries. For security, only
        deposit funds through account details generated by our system or
        provided directly by our official support team.
      </Typography>

      <Typography>
        <img
          src="https://versionobj.ecoassetsservice.com/v14/static/front/img/18plus.png"
          alt="18+IMG"
          width="30"
          height="30"
          style={{ margin: '20px' }}
        />
        <a href="https://www.gamcare.org.uk/" target="_blank">
          <img
            src="https://versionobj.ecoassetsservice.com/v14/static/front/img/gamecare.png"
            alt="gameCare"
            width="30"
            height="30"
            style={{ margin: '20px' }}
          />
        </a>
        <a href="https://www.gamblingtherapy.org/" target="_blank">
          <img
            src="https://versionobj.ecoassetsservice.com/v14/static/front/img/gt.png"
            alt="gambThe"
            width="30"
            height="30"
            style={{ margin: '20px' }}
          />
        </a>
      </Typography>

      <NavLinks>
        <Link href="#" color="inherit">
          Terms and Conditions
        </Link>
        <Link href="#" color="inherit">
          Responsible Gaming
        </Link>
      </NavLinks>
      <CopyrightBox>
        <Typography variant="body2">
          © Copyright 2024. All Rights Reserved. Powered by BetLab.
        </Typography>
        <SocialIcons>
          <InstagramIcon />
          <TelegramIcon />
          <YouTubeIcon />
        </SocialIcons>
      </CopyrightBox>
    </FooterDiv>
  )
}

export default Footer
