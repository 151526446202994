import React from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { BetHistoryUrl } from '../../constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const PnlText = styled(Typography)(({ theme, pnl }) => ({
    color: pnl >= 0 ? theme.palette.success.main : theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
}));

function OrderSummary() {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const itemData = location.state?.itemData;
    const [betHistory, setBetHistory] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const fetchBetHistory = async () => {
            try {
                const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');
                const token = userDetails?.token || '';

                const response = await fetch(BetHistoryUrl, {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'authorization': token,
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        match_id: itemData.match_id,
                        market_id: itemData.market_id,
                        user_id: userDetails?.userId || 0,
                        result_id: null,
                        is_fancy: "",
                        is_back: "",
                        win_status: "",
                        user_name: "",
                        page: 1,
                        total_items: 0,
                        items: 50
                    }),
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch bet history');
                }

                const data = await response.json();
                setBetHistory(data.data.data);
            } catch (err) {
                console.error('Error fetching bet history:', err);
                setError('Failed to fetch bet history');
            } finally {
                setLoading(false);
            }
        };

        if (itemData) {
            fetchBetHistory();
        }
    }, [itemData]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })}`;
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!itemData || betHistory.length === 0) {
        return <Typography>No data available for this order.</Typography>;
    }

    return (
        <Box sx={{ padding: '4px 8px 40px 8px' }}>
            <Button
                startIcon={<ArrowBackIcon />}
                onClick={handleGoBack}
                sx={{ marginBottom: 2 }}
            >
                Back
            </Button>
            <Typography variant='h4' gutterBottom>
                Order Summary
            </Typography>
            <Typography variant='body1' gutterBottom>
                Order ID: {params?.orderid}
            </Typography>

            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Match</strong></TableCell>
                            <TableCell><strong>Market</strong></TableCell>
                            <TableCell><strong>Selection</strong></TableCell>
                            <TableCell><strong>Type</strong></TableCell>
                            <TableCell><strong>Odds</strong></TableCell>
                            <TableCell><strong>Stake</strong></TableCell>
                            <TableCell><strong>P&L</strong></TableCell>
                            <TableCell><strong>Liability</strong></TableCell>
                            <TableCell><strong>Placed At</strong></TableCell>
                            <TableCell><strong>Status</strong></TableCell>
                            <TableCell><strong>Sport</strong></TableCell>
                            <TableCell><strong>Series</strong></TableCell>
                            <TableCell><strong>Commission</strong></TableCell>
                            <TableCell><strong>Device</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {betHistory.map((bet, index) => (
                            <TableRow key={index}>
                                <TableCell>{bet.match_name}</TableCell>
                                <TableCell>{bet.market_name}</TableCell>
                                <TableCell>{bet.selection_name}</TableCell>
                                <TableCell>{bet.is_back === "1" ? "Back" : "Lay"}</TableCell>
                                <TableCell>{bet.odds}</TableCell>
                                <TableCell>{parseFloat(bet.stack).toFixed(2)}</TableCell>
                                <TableCell>
                                    <PnlText pnl={parseFloat(bet.p_l)}>
                                        {parseFloat(bet.p_l).toFixed(2)}
                                    </PnlText>
                                </TableCell>
                                <TableCell>{parseFloat(bet.liability).toFixed(2)}</TableCell>
                                <TableCell>{formatDate(bet.created_at)}</TableCell>
                                <TableCell>{bet.result === "0" ? "Pending" : bet.result}</TableCell>
                                <TableCell>{bet.sport_name}</TableCell>
                                <TableCell>{bet.series_name}</TableCell>
                                <TableCell>{bet.commission}</TableCell>
                                <TableCell>{bet.device_type === 'W' ? 'Web' : 'Mobile'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default OrderSummary;