import React, { useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore, OpenWithRounded } from '@mui/icons-material';
import BetModifyComponent from './BetModifyComponent';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBetSlip } from '../../store/appSlice';

// ... Keep the LiveIndicator styled component ...

const LiveIndicator = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: '#4CAF50',
  marginRight: 8,
  animation: 'blink 1s ease-in-out infinite',
  '@keyframes blink': {
    '0%': { opacity: 0.5 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0.5 },
  },
}));

const BetSlipContainer = styled('div')(({ theme }) => ({
  minHeight: '45vh',
  overflowY: 'auto',

  boxShadow: '0 -5px 20px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
  backdropFilter: 'blur(25px)',
  border: 'none',
}));

const BetSlipHeader = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

// ... Keep the StyledMatchDetails styled component ...

const StyledMatchDetails = styled('div')(({ theme }) => ({
  padding: '8px',
  backgroundColor: theme.palette.contrastColor.main,

}));

const StyledContent = styled('div')(({ theme }) => ({

  padding: '8px',
  overflowY: 'auto',
  
}));

const BetSlipAccordion = () => {
  const dispatch = useDispatch();
  const { betSlip } = useSelector((state) => state.appslice);

  const onClose = () => {
    dispatch(deleteBetSlip());
  };

  return (
    <BetSlipContainer sx={{position:'sticky',right:'0px',top:'70px',zIndex:'1000'}}>
      <BetSlipHeader>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: '400' }}>
          Bet Slip
        </Typography>
      </BetSlipHeader>
      {!betSlip[0] ? (
        <Typography variant="body1" sx={{ padding: '16px', textAlign: 'center' }}>
          No cards available
        </Typography>
      ) : (
        <>
          <StyledMatchDetails>
            <Typography variant='body2' sx={{ fontSize: '12px', fontWeight: '400', display: 'flex', alignItems: 'center', marginBottom: '4px', gap: 1 }}>
              {betSlip[0].market?.match_name || ''}
              {betSlip[0].market?.inplay && <LiveIndicator />}
            </Typography>
            <Typography variant='body2' sx={{ fontSize: '20px', fontWeight: '500' ,lineHeight:'17px'}}>
              {betSlip[0].market?.runners?.[betSlip[0].index]?.name || ''}
            </Typography>
          </StyledMatchDetails>
          <StyledContent>
            <BetModifyComponent
              key={JSON.stringify(betSlip[0])}
              marketDetails={betSlip[0]}
              onCancel={onClose}
              betType={betSlip[0]?.betType}
            />
          </StyledContent>
        </>
      )}
    </BetSlipContainer>
  );
};

export default BetSlipAccordion;