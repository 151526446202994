import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import CookieIcon from '@mui/icons-material/Cookie';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import GavelIcon from '@mui/icons-material/Gavel';
import UpdateIcon from '@mui/icons-material/Update';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoneyIcon from '@mui/icons-material/Money';
import TimerIcon from '@mui/icons-material/Timer';
import BlockIcon from '@mui/icons-material/Block';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography paragraph>
          We are the flagship brand of , offering Our Services to You wherein You can also participate in various Contests hosted on Our Platform. Any person utilising the Platform or any of its features including participation in various Contests being conducted on the Platform shall be bound by the terms of this privacy policy ("Privacy Policy").
        </Typography>

        <Typography paragraph>
          All capitalised terms not defined herein shall have the meaning ascribed to them in the Terms.
        </Typography>

        <Typography paragraph>
          We respect the privacy of its Users and are committed to protect it in all respects. With a view to offer an enriching and holistic internet experience to its Users, We offer a vast repository of Services on Our Platform.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Information Collection
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon>
              <SecurityIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Information supplied by Users" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SecurityIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Information automatically tracked during User's navigation on the Platform" />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Key Sections
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon>
              <CookieIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Use of Cookies" secondary="We use cookies to improve the effectiveness and usability of the Platform" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LinkIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Links" secondary="Our Platform includes links to other websites" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SecurityIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Security Procedures" secondary="All information is securely stored within a controlled database" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DeleteIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="User Account and Data Deletion" secondary="Users can request account deletion or suspension" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GavelIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Applicable Laws and Jurisdiction" secondary="Governed by the laws of the Republic of India" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <UpdateIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Updating Information" secondary="Users should promptly notify us of any changes to their personal information" />
          </ListItem>
        </List>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Box display="flex" alignItems="center">
            <ContactMailIcon color="primary" sx={{ mr: 2 }} />
            <Typography>
              Any questions or clarifications with respect to this Privacy Policy can be sent to us at: [Contact Information]
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Self Exclusion
        </Typography>

        <Typography variant="h5" gutterBottom>
          What is a self-exclusion?
        </Typography>
        <Typography paragraph>
          Self-exclusion is a facility that the Site offers to help those customers who feel that their gambling is out of control and want us to help them stop. By entering into a self-exclusion agreement with the Site, you will be prevented from using your Account (as defined in the terms and conditions) for a specific period, as determined by you, of between 6 months and 5 years.
        </Typography>

        <Typography variant="h5" gutterBottom>
          How to self-exclude from the Site
        </Typography>
        <Typography paragraph>
          If at any time you should you wish to exclude yourself from use of the Site (as defined in the terms and conditions), you must submit this request by email to <Box component="span" sx={{ fontWeight: 'bold' }}>[email address]</Box>.
        </Typography>
        <Typography paragraph>
          Please inform us of the period for which you wish to self-exclude. The minimum is 6 months and the maximum is 5 years. If you request self-exclusion but do not specify a period, we will exclude you for the minimum period of six months ("Minimum Period").
        </Typography>

        <Typography variant="h5" gutterBottom>
          How soon after requesting a self-exclusion will it be activated?
        </Typography>
        <Typography paragraph>
          We will endeavour to apply your exclusion as soon as practically possible. Normally, we will be able to reset your password to prevent you accessing the Site within 24 hours of your request.
        </Typography>

        <Typography variant="h5" gutterBottom>
          What happens if I self-exclude?
        </Typography>
        <Typography paragraph>
          We will make all reasonable efforts to:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Prevent any marketing material being forwarded to you;" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Remove you from any marketing databases operated by us;" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Suspend your activity by cancelling your ability to access the Site for the period requested by you, or if no period is requested by you, for the Minimum Period;" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Permanently close your Customer Account if instructed to do so by you, and return all funds owed to you to your nominated bank account." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Can I re-activate my Account or open a new Account during the self-exclusion period?
        </Typography>
        <Typography paragraph>
          Accounts that have been self-excluded cannot be reactivated under any circumstances until the expiry of the self-exclusion period.
        </Typography>
        <Typography paragraph>
          During the period of your exclusion, you must not attempt to re-open any existing Account(s), seek to open any new Accounts or seek to place bets through any other customer's Account.
        </Typography>

        <Typography variant="h5" gutterBottom>
          If I would like to re-activate my Account, is this possible?
        </Typography>
        <Typography paragraph>
          At the end of the self-exclusion period, you must contact us in person and confirm such intention in writing. If it is decided (in the Site's absolute discretion) to permit you to re-open your Account/open a new Account, you should be aware that a 24-hour waiting period will be imposed prior to the Account being available for use.
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Box display="flex" alignItems="center">
          <EmailIcon color="primary" sx={{ mr: 2 }} />
          <Typography variant="h6">
            Subscribe to notifications
          </Typography>
        </Box>
      </Paper>
    </Container>


    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Responsible Gaming
        </Typography>

        <Typography paragraph>
          The Site is committed to Responsible Gambling and we take our responsibilities towards our customers very seriously. We aim to provide an environment in which you can bet in a safe, fair and above all responsible manner.
        </Typography>

        <Typography paragraph>
          If you feel you may have a problem when it comes to controlling your gambling, please consider using one of our tools aimed at helping this:
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon>
              <MoneyIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Deposit Limits" 
              secondary="By selecting a deposit limit per day, week or month"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <TimerIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Time Out Facility" 
              secondary="By using our &quot;time out&quot; facility to allow you to suspend your account activity for the following durations - 24 hours, one week, one month or any other period as you may reasonably request up to a maximum of 6 weeks"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <BlockIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Self-Exclusion" 
              secondary="Opting for a self-exclusion, the minimum period being six months which means your account will be blocked for a set amount of time. Self-exclusions cannot be undone and may only be unlocked by contacting customer services when the self-exclusion time runs out"
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary">
            We encourage responsible gaming. If you need help, please don't hesitate to use our tools or contact our customer support.
          </Typography>
        </Box>
      </Paper>
    </Container>


    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h3" gutterBottom>
          THE SITE RULES AND REGULATIONS
        </Typography>

        <Typography variant="h4" gutterBottom>
          PART A - INTRODUCTION
        </Typography>

        <Typography variant="h5" gutterBottom>
          Use and interpretation
        </Typography>

        <Typography paragraph>
          These Rules and Regulations ("Rules") are part of the Site's terms and conditions.
        </Typography>

        <Typography paragraph>
          The Rules apply to all bets placed on this online betting platform ("Site"). The Rules consist of the following:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="This INTRODUCTION section (Part A);" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The GENERAL RULES (set out in Part B below); and" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The SPECIFIC SPORTS RULES (set out in Part C below - these apply to certain sports)." />
          </ListItem>
        </List>

        <Typography paragraph>
          The General Rules apply to all bets unless stated otherwise in the Specific Sports Rules. If there is any inconsistency between the Specific Sports Rules and the General Rules, the Specific Sports Rules shall prevail.
        </Typography>

        <Typography paragraph>
          The rules governing how markets are offered, managed and/or settled are not the same for every market on each product. In certain circumstances, a bet that is settled as a winner on one product may be settled as a loser on the other product (and vice versa). Additionally, different settlement rules may apply so that, for example, bets that are a winner on one product may be settled as a dead heat or be voided on the other product. Customers must ensure that they familiarise themselves with the relevant rules that apply to the bets that they place on the Site.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Customer responsibility
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="Customers should make themselves aware of all of the Rules affecting any market on which they wish to place a bet." />
          </ListItem>
          <ListItem>
            <ListItemText primary="In particular, customers who use the &quot;one-click&quot; option to place bets are solely responsible for their actions and the Site shall have no liability to such customers for any errors made by customers when using this option." />
          </ListItem>
        </List>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h4" gutterBottom>
          PART B - GENERAL RULES
        </Typography>

        <Typography variant="h5" gutterBottom>
          Matters beyond the Site's reasonable control and malfunctions
        </Typography>

        <Typography paragraph>
          The Site is not liable for any loss or damage you may suffer because of any: act of God; power cut; trade or labour dispute; act, failure or omission of any government or authority; obstruction or failure of telecommunication services; or any other delay or failure caused by a third party or otherwise outside of our control. In such an event, the Site reserves the right to cancel or suspend access to the Site without incurring any liability.
        </Typography>

        <Typography paragraph>
          The Site is not liable for the failure of any equipment or software howsoever caused, wherever located or administered, or whether under its direct control or not, that may prevent the operation of the Site.
        </Typography>

        <Typography paragraph>
          In the event of a technological failure or error which is apparent to the customer, the customer is obliged to notify the Site of such failure/error immediately. If the customer continues to place a bet in these circumstances, they shall take reasonable action to minimise any potential loss. In the absence of such action, the Site reserves the right to void a bet.
        </Typography>

        {/* Continue with the rest of Part B... */}

        <Divider sx={{ my: 4 }} />

        <Typography variant="h4" gutterBottom>
          PART C - SPECIFIC SPORTS RULES
        </Typography>

        <Typography variant="h5" gutterBottom>
          Cricket
        </Typography>

        <Typography variant="h6" gutterBottom>
          General
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="If a ball is not bowled during a competition, series or match then all bets will be void except for those on any market that has been unconditionally determined (e.g. in the 'Completed Match' market)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If a match is shortened by weather, all bets will be settled according to the official result (including for limited overs matches, the result determined by the Duckworth Lewis method)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="In the event of a match being decided by a bowl-off or toss of the coin, all bets will be void except for those on markets that have been unconditionally determined." />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Test matches
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="If a match starts but is later abandoned for any reason other than weather (which may include but is not limited to: dangerous or unplayable wicket or outfield; pitch vandalism; strike or boycott; crowd protests/violence; stadium damage; acts of terrorism; and acts of God), the Site reserves the right to void all bets, except for those on markets that have been unconditionally determined." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If the match is not scheduled to be completed within five days after the original scheduled completion date, then all bets on markets for this event will be void, except for bets on any markets that have been unconditionally determined." />
          </ListItem>
        </List>

        {/* Continue with the rest of Part C... */}

      </Paper>
    </Container>
    </Container>
  );
};

export default PrivacyPolicy;