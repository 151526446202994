import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Tabs, Tab, AppBar } from '@mui/material'
import CricketEvent from '../Cricket/CricketEvent'
import FootballEvent from '../Football/FootballEvent'
import CasinoEvent from '../Casino/CasinoEvent'
import TennisEvent from '../Tennis/TennisEvent'
import {
  useWebSocketContext,
  useWebSocketSelector,
} from '../../hooks/useWebsocket'
import OpenBets from '../OpenBets/OpenBets'
import MarketDetailsCard from './MatchDetailsCard'
import MarketIcon from '@mui/icons-material/ShowChart' // Add this import
import OpenBetIcon from '@mui/icons-material/ListAlt' // Add this import

const StyledAppBar = styled(AppBar)`
  background-color: #8a1a24;
`

const StyledTabs = styled(Tabs)`
  & .MuiTab-root {
    color: #fff;
    opacity: 0.7;
    min-height: 48px; // Reduce the minimum height
    padding: 6px 12px; // Reduce padding
    font-size: 0.875rem; // Reduce font size
    &.Mui-selected {
      color: #e4e9f0;
      opacity: 1;
    }
  }
  & .MuiTabs-indicator {
    bottom: 0;
    height: 3px;
  }
`

const TabContent = styled.div`
  padding: 8px 8px 48px 8px;
  background-color: ${({ theme }) => 'transparent'};
`

const eventComponents = {
  cricket: CricketEvent,
  football: FootballEvent,
  casino: CasinoEvent,
  tennis: TennisEvent,
}

const CommonEventScreen = () => {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(0)
  const { subscribeToMarket, unSubscribeToMarket } = useWebSocketContext()
  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )
  const navigate=useNavigate()
  const currentMarketId = location.pathname.split('/')[3];
  const currentEventDetails = marketIds[currentMarketId] || {}

  const EventComponent = eventComponents['cricket'] || (() => <div>Event not found</div>)
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (currentEventDetails?.match_id) {
      subscribeToMarket(currentMarketId, currentEventDetails?.match_id, true);
      
      // Return a cleanup function
      return () => {
        unSubscribeToMarket();
      };
    }
  }, [currentMarketId, currentEventDetails?.match_id, subscribeToMarket, unSubscribeToMarket]);

  return (
    <>
    <div style={{marginBottom:'8px'}}>
    <MarketDetailsCard/>
    </div>

      <StyledAppBar position='static'>
        <StyledTabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
     
        >
          <Tab icon={<MarketIcon fontSize="small"/>} label="Market" />
          <Tab icon={<OpenBetIcon fontSize="small"/>} label="Open Bets" />
        </StyledTabs>
      </StyledAppBar>
      <TabContent>
        {activeTab === 0 && <EventComponent />}
        {activeTab === 1 && <OpenBets />}
      </TabContent>
    </>
  )
}

export default React.memo(CommonEventScreen)

