import React, { useEffect } from 'react';
import { Card, Typography, Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import useApiFetch from '../../hooks/useApiFetch';
import { useLocation } from 'react-router-dom';
import { OpenBetsUrl } from '../../constants';
import theme from '../../utils/customTheme';

const StyledCard = styled(Card)(({ theme }) => ({

  marginBottom: theme.spacing(2),
}));

const CardSection = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
}));

const BetRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));
const StyleHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),

}));
const StyledTypography = styled(Typography)(({ theme, color }) => ({
  fontWeight: 'bold',
  color: theme.palette[color].main,
}));

const OpenBets = () => {
  const location = useLocation()
  const currentMatchId = location.pathname.split('/')[4]
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}')
  const { data, loading, error, fetchData } = useApiFetch(OpenBetsUrl, {
    method: 'POST',
    headers: {
      'accept': 'application/json, text/plain, */*',
      'authorization': userDetails.token || '',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      "match_id": currentMatchId,
      "user_id": userDetails.id,
      "result_id": "",
      "is_fancy": "",
      "is_back": "",
      "win_status": "",
      "user_name": "",
      "page": 1,
      "total_items": 0,
      "items": 50
    }),
    credentials: 'include'
  });

  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h5" color="error" gutterBottom>
          Error: {error.message}
        </Typography>
      </Box>
    );
  }
  if (Array.isArray(data?.data?.data) && data?.data?.data.length === 0) {
    return (
      <Box sx={{ padding: 2 }}>
        <Typography variant="h5" gutterBottom>
          No open bets
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Open Bets
      </Typography>
      {loading ? (
        // Skeleton loading state
        Array.from(new Array(3)).map((_, index) => (
          <StyledCard key={index}>
            <Skeleton variant="rectangular" height={150} />
          </StyledCard>
        ))
      ) : (
        Array.isArray(data?.data?.data) && data.data.data.length > 0 ? (
          data.data.data.map((bet, index) => (

            <StyledCard key={index}>
              <StyleHeader>
                <Typography variant="h6" gutterBottom>{bet.match_name}</Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>{new Date(bet.created_at).toLocaleString()}</Typography>
              </StyleHeader>
              <div style={{ display: 'flex' }}>
                <CardSection>
                  <BetRow>
                    <Typography variant="body2">Market:</Typography>
                    <Typography variant="body2">{bet.market_name}</Typography>
                  </BetRow>
                  <BetRow>
                    <Typography variant="body2">Runner:</Typography>
                    <Typography variant="body2">{bet.selection_name}</Typography>
                  </BetRow>
                  <BetRow>
                    <Typography variant="body2">Odds/Price:</Typography>
                    <Typography variant="body2">{bet.odds}</Typography>
                  </BetRow>
                  {/* Add new BetRow for Back/Lay information */}
                  <BetRow>

                    <Typography variant="body2">Type:</Typography>
                    <StyledTypography
                      variant="body2"
                      sx={{
                        padding: 1,
                        borderRadius: '5px',
                        backgroundColor: bet.is_back === '1'
                          ? theme.palette.backButtonColor
                          : theme.palette.layButtonColor
                      }}
                      color={"primary"}
                    >
                      {bet.is_back === '1' ? "Back" : "Lay"}
                    </StyledTypography>
                  </BetRow>
                </CardSection>
                <CardSection>
                  <BetRow>
                    <Typography variant="body2">Stake:</Typography>
                    <StyledTypography variant="body2" color="primary">
                      {parseFloat(bet.stack).toFixed(2)}
                    </StyledTypography>
                  </BetRow>
                  <BetRow>
                    <Typography variant="body2">P/L:</Typography>
                    <StyledTypography variant="body2" color="success">
                      {parseFloat(bet.p_l).toFixed(2)}
                    </StyledTypography>
                  </BetRow>
                  <BetRow>
                    <Typography variant="body2">Liability:</Typography>
                    <StyledTypography variant="body2" color="error">
                      {parseFloat(bet.liability).toFixed(2)}
                    </StyledTypography>
                  </BetRow>
                </CardSection>
              </div>
            </StyledCard>
          ))
        ) : (
          <Typography variant="body1">No open bets available.</Typography>
        )
      )}
    </Box>
  );
};

export default OpenBets;