import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  isConnected: false,
  status: 'DISCONNECTED',
  data: null,
  marketIds: {},
  eventOdds: {},
  lastUpdateTime: null,
  updateFrequency: [],
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    connect: (state) => {
      state.status = 'CONNECTING';
    },

    setIsConnected: (state, action) => {
      state.isConnected = action.payload;
      state.status = action.payload ? 'CONNECTED' : 'DISCONNECTED';
    },
    setWebSocketStatus: (state, action) => {
      state.status = action.payload;
    },
    setWebSocketData: (state, action) => {
      if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
        state.data = action.payload;
      }
    },
    updateMarketIds: (state, action) => {
      const newMarketIds = {};

      action.payload.forEach(item => {
        newMarketIds[item.market_id] = item;
      });
      if (JSON.stringify(state.marketIds) !== JSON.stringify(newMarketIds)) {
        state.marketIds = newMarketIds;
      }
    },
    updateGameOdds: (state, action) => {
      console.log('data')
      console.log('NEW ODDS',action.payload)
      const newOdds = JSON.parse(action.payload || '{}');
      state.eventOdds=newOdds;

    },
    clearWebSocketData: (state) => {
      state.data = null;
      state.marketIds = {};
      state.eventOdds = {};
    },

    resetWebSocketState: (state) => {
      // Reset all relevant state properties
      state.isConnected = false
      state.webSocketData = null
      state.marketIds = []
      state.gameOdds = {}
      // Add any other state properties that need to be reset
    },
  },
});

export const {
  connect,

  setIsConnected,
  setWebSocketStatus,
  setWebSocketData,
  updateMarketIds,
  updateGameOdds,
  clearWebSocketData,
  resetWebSocketState,
} = websocketSlice.actions;

export default websocketSlice.reducer;

// Memoized Selectors
export const selectWebSocketStatus = state => state.websocket.status;
export const selectWebSocketData = state => state.websocket.data;
export const selectMarketIds = state => state.websocket.marketIds;
export const selecteventOdds = state => state.websocket.eventOdds;

export const selectGameOddsByMarketId = createSelector(
  [selecteventOdds, (_, marketId) => marketId],
  (eventOdds, marketId) => eventOdds[marketId]
);

export const selectMarketIdsByType = createSelector(
  [selectMarketIds, (_, type) => type],
  (marketIds, type) => Object.values(marketIds).filter(market => market.type === type)
);