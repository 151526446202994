import React, { useState, useCallback } from 'react';
import {
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
  InputBase,
  styled,
  Link
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import debounce from 'lodash/debounce';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  padding:'0px 10px',
  border:'none !important',
  
  '& .MuiInputBase-root': {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    border:'none !important',
    backgroundColor: 'transparent',
    color:'white',

   [theme.breakpoints.up('sm')]:{
    width:'100%'
   },

    '&:hover': {
      boxShadow: theme.shadows[1],
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
  backgroundColor: 'transparent'
}));

const SearchBar = ({ searchFunction }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      const autoCompleteOptions = searchFunction(searchValue);
      if(autoCompleteOptions.length===0){
        setOptions(null)
      }
      setOptions(autoCompleteOptions);
    }, 300),
    [searchFunction]
  );

  const handleChange = (event, newValue) => {
    if (newValue && newValue.url) {
      window.open(newValue.url, '_blank');
    }
    setSearchTerm(newValue ? newValue.label : '');
    debouncedSearch(newValue ? newValue.label : '');
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    debouncedSearch(newInputValue);
  };

  const handleClear = () => {
    setSearchTerm('');
    setOptions([]);
  };

  return (
    <StyledAutocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
      value={searchTerm}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderOption={(props, option) => (
        <li {...props}>
          <Link href={option.url} underline="none" color="inherit">
            {option.label}
          </Link>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {searchTerm && (
                  <IconButton aria-label="clear" onClick={handleClear} edge="end">
                    <ClearIcon />
                  </IconButton>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchBar;