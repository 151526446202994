import React, { useState, useEffect,useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  styled,
  useMediaQuery,
  useTheme,
  Collapse,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Pagination,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ProfitLossUrl } from '../../constants';

const ProfitLossStatement = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const FilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const StatementCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StatementRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const PnlText = styled(Typography)(({ theme, pnl }) => ({
  color: pnl >= 0 ? theme.palette.success.main : theme.palette.error.main,
}));

const FilterBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const ProfitLossPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDetails, setOpenDetails] = useState({});
  const [profitLossData, setProfitLossData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sportId, setSportId] = useState('');
  const [userId, setUserId] = useState(JSON.parse(sessionStorage.getItem('userDetails') || '{}').id || 0);

  useEffect(() => {
    fetchProfitLossData();
  }, [page]);

  const fetchProfitLossData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(ProfitLossUrl, {
        method: 'POST',
        headers: {
          "accept": "application/json, text/plain, */*",
          "authorization": JSON.parse(sessionStorage.getItem('userDetails') || '{}').token || '',
          "content-type": "application/json",
        },
        body: JSON.stringify({
          sport_id: sportId,
          user_id: userId,
          page: page - 1,
          items: 50,
          total_items: 0
        }),
        credentials: "include"
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setProfitLossData(data.data || []);
      setTotalPages(data.pageCount || 1);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [page, sportId, userId]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const toggleDetails = (eventId) => {
    setOpenDetails(prevState => ({
      ...prevState,
      [eventId]: !prevState[eventId]
    }));
  };

  const renderProfitLossCard = (betData) => (
    <StatementCard key={betData.market_id}>
      <StatementRow>
        <Typography variant="subtitle1">{formatDate(betData.created_at)}</Typography>
        <PnlText variant="subtitle1" pnl={parseFloat(betData.amount)}>
          P&L: {betData.amount}
        </PnlText>
      </StatementRow>
      <Box>
        <StatementRow onClick={() => toggleDetails(betData.market_id)} style={{ cursor: 'pointer' }}>
          <Typography variant="body1">{betData.match_name}</Typography>
          {openDetails[betData.market_id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </StatementRow>
        <Collapse in={openDetails[betData.market_id]}>
          <DetailRow>
            <Typography variant="body2">Sport: {betData.sport_name}</Typography>
          </DetailRow>
          <DetailRow>
            <Typography variant="body2">Market: {betData.market_name}</Typography>
          </DetailRow>
          <DetailRow>
            <Typography variant="body2">Result: {betData.result}</Typography>
          </DetailRow>
          <DetailRow>
            <Typography variant="body2">Stack:</Typography>
            <Typography variant="body2">{betData.stack}</Typography>
          </DetailRow>
          <DetailRow>
            <Typography variant="body2">Commission:</Typography>
            <Typography variant="body2">{betData.commission}</Typography>
          </DetailRow>
        </Collapse>
      </Box>
    </StatementCard>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ProfitLossStatement>
      <Header>
        <Typography variant="h6">Betting Profit & Loss</Typography>
      </Header>
      
      <FilterBox>
        <FormControl fullWidth>
          <InputLabel>Select Sports</InputLabel>
          <Select
            value={sportId}
            onChange={(e) => setSportId(e.target.value)}
            label="Select Sports"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="2">Football</MenuItem>
            <MenuItem value="3">Tennis</MenuItem>
            <MenuItem value="4">Cricket</MenuItem>
            <MenuItem value="-1">Live Game</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={fetchProfitLossData} startIcon={<FilterListIcon />}>
          Apply Filter
        </Button>
      </FilterBox>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      )}

      {profitLossData.data?.length > 0 ? (
        profitLossData.data.map(renderProfitLossCard)
      ) : (
        <Typography>No profit & loss data available</Typography>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </ProfitLossStatement>
  );
};

export default ProfitLossPage;