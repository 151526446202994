import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFound() {
    const navigate= useNavigate()
    
  return (
    <div>Not Found this page</div>
  )
}

export default NotFound