import React, { useState, useEffect } from "react";
import { Divider, Typography, Switch, styled, Skeleton, Button, useMediaQuery, IconButton } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useWebSocketSelector } from "../../hooks/useWebsocket";
import { formattedDate } from "../../utils/formattedDate";
import { iconsConst } from "../../utils/const";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import theme from "../../utils/customTheme";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const MetallicCreditCard = styled('div')(({ theme }) => ({
  background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url(${'/stadiumCardBackground.webp'})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '0px',
  padding: '20px',
  color: '#e0e0e0',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
  minHeight: '120px',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)', // Dark backdrop
    zIndex: 1,
  },

  '& > *': {
    position: 'relative',
    zIndex: 2,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.02) 1px,
      transparent 1px,
      transparent 2px
    )`,
    zIndex: 3,
    pointerEvents: 'none',
  },

  '& > *': {
    position: 'relative',
    zIndex: 3,
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '10px',
  zIndex: 4,
  color: '#e0e0e0',
  backgroundColor: theme.palette.buttonSecondaryColor,
  minWidth: '40px', // Reduced width
  width: '40px', // Set a fixed width
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const SeriesName = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '15px',
  left: '100px', // Adjust this value to position it properly next to the back button
  zIndex: 4,
  color: '#e0e0e0',
}));

const FullscreenButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  zIndex: 4,
  color: '#e0e0e0',
  backgroundColor: theme.palette.buttonSecondaryColor,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const FullscreenContainer = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999,
  backgroundColor: '#000',
});

const MatchDetailsCard = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const currentEventId = location.pathname.split('/')[3]
  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )
  const [liveTV, setLiveTV] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFullscreen, setIsFullscreen] = useState(false);

  const currentMarketData = marketIds[currentEventId]
  const currentGameDate = currentMarketData && formattedDate(currentMarketData.date, 'dd MMM yyy')

  const [scoreboardError, setScoreboardError] = useState(false);
  const [scoreboardUrl, setScoreboardUrl] = useState('');
  const [scoreboardLoaded, setScoreboardLoaded] = useState(false);
  
  useEffect(() => {
    const checkForErrors = () => {
      const iframe = document.querySelector('iframe[title="Score Board"]');
      if (iframe) {
        try {
          // Try to access the iframe content
          const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
          const errorBody = iframeDocument.querySelector('body.neterror');
          
          if (errorBody) {
            // If we find the error body, extract the URL from the iframe src
            const currentSrc = iframe.src;
            const newUrl = currentSrc.replace('http://', 'https://');
            setScoreboardUrl(newUrl);
            setScoreboardError(true);
          } else {
            setScoreboardError(false);
            // setScoreboardLoaded(true);
          }
        } catch (error) {
          // If we can't access the iframe content due to CORS, assume there's an error
          const currentSrc = iframe.src;
          const newUrl = currentSrc.replace('http://', 'https://');
          setScoreboardUrl(newUrl);
          setScoreboardError(true);
        }
      }
    };

    // Check immediately and set up interval only if scoreboard is not loaded
    checkForErrors();
    let intervalId;
    if (!scoreboardLoaded) {
      intervalId = setInterval(checkForErrors, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [scoreboardLoaded]); // Add scoreboardLoaded to dependency array

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const renderLiveTV = () => {
    let tvUrl = currentMarketData?.tv_url;
    if (tvUrl.includes('https://dtv.diamondapi.uk/ctv/index.php')) {
      tvUrl = tvUrl.replace('https://dtv.diamondapi.uk/ctv/index.php', 'https://dtv.diamondapi.uk/ctv/index.html')
    }
    return (
      <div style={{ padding: '10px 0' }}> {/* Added padding */}
        <iframe
          src={tvUrl}
          style={{ 
            width: '100%', 
            height: isFullscreen ? '100%' : '150px', 
            border: 'none', 
            overflow: 'hidden' 
          }}
          title="Live TV"
        />
        <FullscreenButton onClick={toggleFullscreen}>
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </FullscreenButton>
      </div>
    );
  }

  const renderScoreBoard = () => {
    let scoreUrl = currentMarketData?.score_url;
    if (scoreUrl.includes('https://diamondapi.uk/dcasino/graph.php')) {
      scoreUrl = scoreUrl.replace('https://diamondapi.uk/dcasino/graph.php', 'https://172.235.1.180/dcasino/graph.php');
    }

    return (
      <div style={{ padding: '10px 0' }}> {/* Added padding */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(scoreboardUrl, '_blank')}
            style={{ marginBottom: '10px' }}
          >
            Show Scoreboard
          </Button>
        <iframe
          src={scoreUrl}
          style={{ 
            width: '100%', 
            height: isFullscreen ? '100%' : '150px', 
            border: 'none', 
            overflow: 'hidden' 
          }}
          title="Score Board"
          onLoad={() => setScoreboardLoaded(true)}
        />
        <FullscreenButton onClick={toggleFullscreen}>
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </FullscreenButton>
      </div>
    );
  };

  const matchName = currentMarketData?.match_name.split('v')
  const icon = iconsConst['cricketWhite']

  const isMatchActive = () => {
    if (!currentMarketData || !currentMarketData.date) return false;
    const matchDate = new Date(currentMarketData.date);
    const now = new Date();
    return matchDate <= now;
  };

  const renderMatchStatus = () => {
    if (isMatchActive()) {
      return liveTV ? renderLiveTV() : renderScoreBoard();
    } else {
      const matchDate = new Date(currentMarketData.date);
      const timeUntilMatch = matchDate - new Date();
      const daysUntilMatch = Math.floor(timeUntilMatch / (1000 * 60 * 60 * 24));
      const hoursUntilMatch = Math.floor((timeUntilMatch % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  
      return (
        <Typography variant="h6" align="center" style={{ padding: '20px 0' }}>
          Match will start in {' '}
          {daysUntilMatch > 0 && ` ${daysUntilMatch} day${daysUntilMatch !== 1 ? 's' : ''} and `}
          {hoursUntilMatch} hour{hoursUntilMatch !== 1 ? 's' : ''}
        </Typography>
      );
    }
  };
  

  if (!currentMarketData) {
    return <div> <Skeleton height={'200px'} /></div>
  }

  if (isFullscreen) {
    return (
      <FullscreenContainer>
        {liveTV ? renderLiveTV() : renderScoreBoard()}
      </FullscreenContainer>
    );
  }

  return (
    <MetallicCreditCard>
      <BackButton
        startIcon={<ArrowBackIosNewIcon />}
        onClick={() => navigate(-1)}
        size="small"

      >
      </BackButton>
      <SeriesName sx={{ fontSize: "1rem" }}>
        {currentMarketData?.series_name}
      </SeriesName>
      <div style={{ marginTop: '10px' }}> {/* Increased top margin to accommodate the back button and series name */}
        <div className="matchDetailsHeader">
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>{icon}</span>
            <Typography sx={{ fontSize: isMobile ? "0.75rem" : "1.5rem" }}>{matchName[0]}</Typography>
            <Typography sx={{ fontSize: isMobile ? "0.70rem" : "1rem" }}>v/s</Typography>
            <Typography sx={{ fontSize: isMobile ? "0.75rem" : "1.5rem" }}>{matchName[1]}</Typography>
          </div>

          <div className="matchDetailsHeader-right">
            <Switch
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: "grey",
                },
                "& .Mui-checked + .MuiSwitch-track": {
                  backgroundColor: theme.palette.buttonSecondaryColor, // Updated to use buttonSecondary color
                },
                "& .MuiSwitch-thumb": {
                  backgroundColor: theme.palette.buttonSecondaryColor, // Updated to use buttonSecondary color
                },
              }}
              {...label}
              size="small"
              onChange={() => setLiveTV(!liveTV)}
              checked={liveTV}
            />
            <Typography sx={{ fontSize: "12px" }}>Watch Live</Typography>
          </div>
        </div>

      </div>
      <Divider />
      {renderMatchStatus()}
    </MetallicCreditCard>
  );
};

export default React.memo(MatchDetailsCard);