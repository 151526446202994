import React, { useEffect, useMemo, useState } from 'react'
import { Grid, Typography, Card } from '@mui/material'
import './CricketGrid.css'
import './../0_Common/0_Common.css'
import ListCard from '../0_Common/ListCard'
import { useWebSocketContext, useWebSocketSelector } from '../../hooks/useWebsocket'

const CricketGrid = () => {

  const { marketsIdsArray } =
  useWebSocketContext()
const [mergedMarketIds, setMergedMarketIds] = useState({})
const { marketIds } = useWebSocketSelector((state) => state.websocket)

const marketIdsList = useMemo(
  () => Object.values(marketIds)?.map((i) => String(i.market_id)),
  [marketIds],
)

// Effect for merging marketIds and marketsIdsArray
useEffect(() => {
  const updatedMarketIds = { ...marketIds }
  marketsIdsArray.forEach((i) => {
    if (updatedMarketIds[i.market_id]) {
      updatedMarketIds[i.market_id] = {
        ...updatedMarketIds[i.market_id],
        ...i,
      }
    }
  })
  setMergedMarketIds(updatedMarketIds)
}, [marketIds, marketsIdsArray])

let data = useMemo(() => {
  const uniqueData = {}
  Object.values(mergedMarketIds).forEach((item) => {
    if (!uniqueData[item.id]) {
      uniqueData[item.id] = item
    }
  })
  return Object.values(uniqueData)
}, [mergedMarketIds])


  return (
    <div>
      <Card 
        style={{
          backgroundImage: 'url("/stadiumCricket.webp")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '70px',
          position: 'relative',
          marginBottom: '8px'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h4" style={{ color: 'white', fontWeight: '' }}>
            Cricket
          </Typography>
        </div>
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          {data && (
            <ListCard
              data={data}
              active={true}
              showNonActive="active"
              limitProp={4}
            />
          )}
          <div style={{ marginBottom: '8px', backgroundColor: '' }}></div>
          {data && (
            <ListCard
              data={data}
              limitProp={2}
              active={false}
              showNonActive="upcoming"
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(CricketGrid)
