import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Grid,
  Skeleton,
  styled,
  Typography,
  Button,
} from '@mui/material'
import EventListCard from '../ListComponent/EventListCard'
import PlayIcon from '../../icons/PlayIcon'
import { keyframes } from '@emotion/react'
import { iconsConst } from '../../utils/const'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useWebSocketContext } from '../../hooks/useWebsocket'
import UpcomingIcon from '../../icons/upcomingicon'

// Styled components
const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#d4ffd4',
  padding: '0.5em 1.1em',
  marginBottom: '0.2em',
  display: 'grid',
  gridTemplateColumns: '63% 37%',
  alignItems: 'center',
  gridTemplateColumns: '63% 12.33% 12.33% 12.33%',
}))

const waveAnimation = keyframes`
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
`

const pulseAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`

const RotatingExpandMoreIcon = styled(ExpandMoreIcon)(({ isexpanded }) => ({
  transition: 'transform 0.3s ease-in-out',
  transform: isexpanded ? 'rotate(180deg)' : 'rotate(0deg)',
}))

const AnimatedPlayIcon = styled(PlayIcon)`
  animation: ${pulseAnimation} 1.5s ease-in-out infinite;
`
const AnimatedUpcomingIcon = styled(UpcomingIcon)`
  animation: ${pulseAnimation} 1.5s ease-in-out infinite;
`

const StyledLiveGrid = styled(Grid)(({ theme, isupcoming }) => ({
  background: isupcoming === 'true' ? theme.palette.warning.main : 'linear-gradient(90deg, #228B22 0%, #50C878 50%, #228B22 100%)',
  backgroundSize: '200% 100%',
  animation: `${waveAnimation} 3s ease-in-out infinite`,
  padding: '8px 10px',
  display: 'flex',
  gap: 5,
  fontSize: '1.1em',
  fontWeight: '500',
  color: 'white',
  alignItems: 'center',
}))

const StyledCard = styled(Grid)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginBottom: 20,
  borderRadius: '10px',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 15px 0px',
}))

const GridItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const CardRoot = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,

  boxShadow: theme.shadows[0],
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  marginBottom: theme.spacing(2),
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  letterSpacing: '0.5px',
}))

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  marginTop: theme.spacing(0.5),
}))


const TitleContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
  // borderRadius: `6px 6px 0 0`,

}))


// Helper functions
const groupEventsByType = (data, marketsIdsArray) => {
  const currentDate = new Date()
  const groupedEvents = {}

  data.forEach((event) => {
    const currentMarketData = marketsIdsArray.find(
      (i) => i.market_id === event.market_id,
    )
    const mergedEvent = { ...currentMarketData, ...event }

    if (!groupedEvents[event.sport_name]) {
      groupedEvents[event.sport_name] = {
        active: [],
        notActive: [],
        upcoming: [],
      }
    }

    if (mergedEvent.inplay === true) {
      groupedEvents[event.sport_name].active.push(mergedEvent)
    } else if (mergedEvent.inplay === false) {
      groupedEvents[event.sport_name].notActive.push(mergedEvent)
    }

    groupedEvents[event.sport_name].upcoming.push(mergedEvent)
  })

  return groupedEvents
}

const ListCard = React.memo(
  ({ data, showNonActive = 'active', active = false, limitProp = -1 }) => {
    const [gameCategoryExpanded, setGameCategoryExpanded] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)
    const { marketsIdsArray } = useWebSocketContext()

    const CardLoading = () => (
      <CardRoot>
        <StyledLiveGrid
          item
          md={12}
          xs={12}
          lg={12}
          justifyContent={'space-between'}
          isupcoming={!active ? 'true' : 'false'}
          style={{
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.08)',
            transform: 'translateZ(1px)',
          }}
        >
          {/* <TitleTypography>In Play</TitleTypography> */}
          <Typography
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5em',
                  }}
                >
                  {active ? <AnimatedPlayIcon /> : <AnimatedUpcomingIcon />}
                  <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                    {active ? 'In Play' : 'Upcoming'}

                  </Typography>
                </Typography>
          <SubtitleTypography variant="subtitle2">
            <Skeleton width={150} />
          </SubtitleTypography>
        </StyledLiveGrid>
        <Grid container spacing={1} sx={{ padding: '8px' }}>
          {[...Array(2)].map((_, index) => (
            <React.Fragment key={index}>
              <Grid item xs={7}>
                <Skeleton variant="text" width="80%" />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton variant="rectangular" height={40} />
              </Grid>
              <Grid item xs={2.5}>
                <Skeleton variant="rectangular" height={40} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardRoot>
    )
    
    const groupedEvents = useMemo(() => {
      try {
        const eventsByGameType = groupEventsByType(data, marketsIdsArray)
        setGameCategoryExpanded((prevState) => {
          const newState = { ...prevState }
          Object.keys(eventsByGameType).forEach((key) => {
            if (!(key in newState)) {
              newState[key] = false
            }
          })
          return newState
        })
        return Object.entries(eventsByGameType).map(([gameType, events]) => ({
          gameType,
          events,
        }))
      } catch (err) {
        console.error('Error in grouping events:', err)
        setError('Failed to process event data')
        return []
      }
    }, [data, marketsIdsArray])

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 500)

      return () => clearTimeout(timer)
    }, [])

    const handleToggle = useCallback((gameType) => {
      setGameCategoryExpanded((prev) => ({
        ...prev,
        [gameType]: !prev[gameType],
      }))
    }, [])

    const renderEvents = useCallback(
      (events, gameType, eventType) => {
        const isExpanded = gameCategoryExpanded[gameType]

        const eventsToRender = isExpanded
          ? events[eventType]
          : events[eventType].slice(0, limitProp !== -1 ? limitProp : 2)

        if (!eventsToRender?.length) {
          return <Typography sx={{ padding: 'auto', textAlign: 'center' }}>No events</Typography>
        }
        return eventsToRender.sort((a, b) => new Date(a.date) - new Date(b.date)).map((ele, idx) => (
          <EventListCard key={`${ele.id}-${idx}`} event={ele} idx={idx} />
        ))
      },
      [gameCategoryExpanded, limitProp],
    )

    if (isLoading || error || !data?.length) {
      return <CardLoading />
    }

    return (
      <>
        {groupedEvents.map(({ gameType, events }) => {
          const isExpanded = gameCategoryExpanded[gameType]
          const showExpandButton =
            events.active?.length +
            events.notActive?.length +
            events.upcoming?.length >
            2

          return (
            <StyledCard key={gameType}>
              <StyledLiveGrid
                item
                md={12}
                xs={12}
                lg={12}
                justifyContent={'space-between'}
                isupcoming={!active ? 'true' : 'false'}
                style={{
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.08)',
                  transform: 'translateZ(1px)',
                }}
              >
                <Typography
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5em',
                  }}
                >
                  {active ? <AnimatedPlayIcon /> : <AnimatedUpcomingIcon />}
                  <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                    {active ? 'In Play' : 'Upcoming'}

                  </Typography>
                </Typography>
                {active && <Typography sx={{ fontWeight: 600, fontSize: '15px' }}>
                  Open Bets
                </Typography>}
              </StyledLiveGrid>

              <StyledGrid container>
                <GridItem item style={{ justifyContent: 'flex-start' }}>
                  {iconsConst[gameType?.toLowerCase()]}
                  <Typography component="div" sx={{ fontWeight: 600, fontSize: '12px' }} ml={1}>
                    {gameType}
                  </Typography>
                </GridItem>
                <GridItem item>
                  <Typography>1</Typography>
                </GridItem>
                <GridItem item>
                  <Typography>X</Typography>
                </GridItem>
                <GridItem item>
                  <Typography>2</Typography>
                </GridItem>
              </StyledGrid>

              {showNonActive === 'active' && renderEvents(events, gameType, 'active')}
              {showNonActive === 'upcoming' && <>{renderEvents(events, gameType, 'upcoming')}</>}
              {showNonActive === 'notActive' && <>{renderEvents(events, gameType, 'notActive')}</>}
              {showExpandButton && events[showNonActive]?.length > limitProp && (
                <Button
                  onClick={() => handleToggle(gameType)}
                  endIcon={
                    <RotatingExpandMoreIcon isexpanded={isExpanded ? 1 : 0} />
                  }
                  fullWidth
                >
                  {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
              )}
            </StyledCard>
          )
        })}
      </>
    )
  },
)

export default ListCard
