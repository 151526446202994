import { styled, Typography } from '@mui/material'
import React from 'react'

const StyledLogo = styled(Typography)(({ theme, size, color }) => ({
  fontWeight: 'bold',
  fontSize: size,
  marginRight: theme.spacing(2),
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: color,
  animation: '$shine 2s linear infinite',
  '@keyframes shine': {
    '0%': { backgroundPosition: '0% 50%' },
    '100%': { backgroundPosition: '200% 50%' },
  },
}))

function Logo({ size = '1.5rem', color = 'transparent' }) {
  return (
    <StyledLogo size={size} color={color}>
      {window.ENV_CONFIG?.REACT_APP_TITLE || process.env.REACT_APP_TITLE || 'Betlab'}
    </StyledLogo>
  )
}

export default Logo