import { Grid } from "@mui/material";
import * as React from "react";
import MatchDetailsCard from "../0_Common/MatchDetailsCard";
import MatchOddsCard from "../0_Common/MatchOddsCard";
import BookmakerCard from "../0_Common/BookmakerCard";
import FancyCard from "../0_Common/FancyCard";

const TennisEvent = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <MatchDetailsCard />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <MatchOddsCard />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <BookmakerCard />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FancyCard />
      </Grid>
    </Grid>
  );
};

export default TennisEvent;
