import React, { Suspense, useEffect, useMemo, useState } from 'react'
import {
  Skeleton,
  Grid,
  Typography,
  styled,
  Box,
} from '@mui/material'
import { alpha, keyframes } from '@mui/material/styles'
import './../0_Common/0_Common.css'
import ListCard from '../0_Common/ListCard'
import SpadeIcon from '../../icons/spadeicon'
import PopularIcon from '../../icons/popularIcon'
import UpcomingIcon from '../../icons/upcomingicon'
import CasinoIcon from '../../icons/casinoicon.jsx'
import {
  useWebSocketContext,
  useWebSocketSelector,
} from '../../hooks/useWebsocket'
import DashboardCarousal from '../DashboardCarousal/DashboardCarousal.js'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import _ from 'lodash'

const buttonSecondaryColor = '#F83C53'


const gameCategories = {
  indianCardGames: [
    {
      name: 'Teen Patti',
      image:
        'https://mac.imgix.net/gemexchimages/teenpattit20.webp?auto=format',
      tag: 'LIVE',
    },
    {
      name: 'Teen Patti Test',
      image:
        'https://mac.imgix.net/gemexchimages/teenpatti_test.png?auto=forma',
    },
    {
      name: '32 Cards Casino',
      image:
        'https://mac.imgix.net/gemexchimages/32casinovirtual.webp?auto=format',
    },
    {
      name: 'Andar Bahar Anthony',
      image:
        'https://mac.imgix.net/gemexchimages/andarbahar1.webp?auto=format',
    },
    {
      name: 'Teen Patti T20',
      image:
        'https://mac.imgix.net/gemexchimages/teenpattit20virtual.webp?auto=format',
    },
    {
      name: '20-20 Poker',
      image: 'https://mac.imgix.net/gemexchimages/2020poker.webp?auto=format',
    },
    {
      name: 'Hi-Low',
      image:
        'https://mac.imgix.net/gemexchimages/hilowvirtual.webp?auto=format',
    },
    {
      name: 'Casino War',
      image: 'https://mac.imgix.net/gemexchimages/casinowar.png?auto=format',
    },
    {
      name: 'Muflis Teen Patti',
      image:
        'https://mac.imgix.net/gemexchimages/teenpattit20virtual.webp?auto=format',
    },
    {
      name: 'Andar Bahar',
      image:
        'https://mac.imgix.net/gemexchimages/teenpattit20virtual.webp?auto=format',
    },
    {
      name: 'Roulette',
      image:
        'https://mac.imgix.net/gemexchimages/andarbahar1.webp?auto=format',
    },
    {
      name: 'Queen Race',
      image: 'https://mac.imgix.net/gemexchimages/Queen_race.png?auto=format',
    },
  ],
  popularGames: [
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      tag: 'LIVE',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
  ],
  casino: [
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      tag: 'LIVE',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
    {
      name: '',
      image:
        'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
    },
  ],
}


const config = {
  sectionIcons: {
    indianCardGames: <SpadeIcon color={buttonSecondaryColor} />,
    popularGames: <PopularIcon color={buttonSecondaryColor} />,
    casino: <CasinoIcon color={buttonSecondaryColor} />,
    upcomingEvents: <UpcomingIcon color={'white'} />,
  },
  sports: {
    Cricket: { color: '#8b4513' },
    Football: { color: '#8b0000' },
  },
  tags: {
    NEW: { backgroundColor: 'red' },
    LIVE: { backgroundColor: 'green' },
    POPULAR: { backgroundColor: 'blue' },
  },
}

const StyledCard = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  marginBottom: 20,
  padding: theme.spacing(1),
  borderRadius: '10px',
  boxShadow: 'none',
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '10px',
  color: theme.palette.primary.main,
  display: 'flex',
  gap: '10px',
  padding: '10px 8px',
  marginBottom: '10px',
  alignItems: 'center',
}))

const moveShine = keyframes`
  0% {
    transform: translateX(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) rotate(30deg);
  }
`

const GameCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.5),
  position: 'relative',
  boxShadow: theme.shadows[0],
  transition: 'transform 0.3s ease-in-out',
  cursor: 'pointer',
  overflow: 'hidden', // Add this to contain the shine effect
  '&:hover': {
    transform: 'scale(1.05)',
    zIndex: 1,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -50,
    left: -50,
    width: '200%',
    height: '200%',
    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,0) 100%)',
    transform: 'rotate(30deg)',
    transition: '0.5s',
    animation: `${moveShine} 3s infinite linear`,
    zIndex: 2, // Ensure the shine effect is above the image but below any text
  },
  '&:hover::before': {
    animation: 'none',
    left: '125%',
  },
}))

const GameImage = styled(LazyLoadImage)(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
}))

const GameTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  left: theme.spacing(1),
  color: theme.palette.common.white,
  fontSize: '14px',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
}))

const GameTag = styled('div')(({ theme, tagType }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  backgroundColor:
    config.tags[tagType]?.backgroundColor || theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: '2px 5px',
  fontSize: '10px',
  borderRadius: theme.shape.borderRadius,
}))

const GameImageWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden', // Add overflow hidden to prevent image from overflowing on hover
  borderRadius: theme.shape.borderRadius,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(to top, ${alpha(theme.palette.common.black, 0.8)} 0%, ${alpha(theme.palette.common.black, 0)} 50%)`,
    borderRadius: theme.shape.borderRadius,
    transition: 'opacity 0.3s ease-in-out', // Add transition for gradient
  },
  '&:hover::after': {
    opacity: 0.7, // Reduce opacity of gradient on hover
  },
}))

const HomeGrid = () => {
  const { subscribeToMarket, unSubscribeToMarket, marketsIdsArray } =
    useWebSocketContext()
  const { marketIds, status } = useWebSocketSelector((state) => state.websocket)

  const marketIdsList = useMemo(
    () => Object.values(marketIds)?.map((i) => String(i.market_id)),
    [marketIds],
  )

  // Effect for subscribing to markets
  useEffect(() => {
    subscribeToMarket(marketIdsList)
    return () => unSubscribeToMarket(marketIdsList)
  }, [marketIdsList, subscribeToMarket, unSubscribeToMarket])


  const data = useMemo(() => {
    return _.chain(marketIds)
      .filter(market => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const matchDate = new Date(market.date);
        matchDate.setHours(0, 0, 0, 0);
        return matchDate >= today;
      })
      .groupBy('sport_name')
      .flatMap(sportGroup => 
        _.chain(sportGroup)
          .uniqBy('match_id')
          .value()
      )
      .value();
  }, [marketIds]);


  // const CardLoading = () => (
  //   <>
  //     {[...Array(2)].map((_, index) => (
  //       <>
  //         <Skeleton key={index} animation="wave" height={'50px'} />
  //         <br></br>
  //       </>
  //     ))}
  //   </>
  // )

  const isLoading = JSON.parse(sessionStorage.getItem('userDetails') || '{}')?.token 
  ? status === 'CONNECTING' 
  : !sessionStorage.getItem('matchesFetched')

  return (
    <Box>
      {/* <Suspense fallback={<CardLoading />}> */}
        {/**
         * Render Inplay
         */}
        <Grid item xs={12} sm={12} md={12} lg={12} container sx={{ marginBottom: '10px' }}>
            <DashboardCarousal />
        </Grid>

        <ListCard
          data={data || []}
          active={true}
          showNonActive="active"
          limitProp={4}
        />
      {/* </Suspense> */}

      {/**
       * Render Upcoming
       */}
      {/* <Suspense fallback={<CardLoading />}> */}
        <ListCard
          data={data || []}
          limitProp={2}
          active={false}
          showNonActive="upcoming"
        />
      {/* </Suspense> */}

      {!isLoading && Object.entries(gameCategories).map(([category, games]) => (
        <React.Fragment key={category}>
          <StyledCard>
            <SectionTitle>
              {config.sectionIcons[category]}
              {category.charAt(0).toUpperCase() +
                category
                  .slice(1)
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}
            </SectionTitle>
            <Grid container spacing={1}>
              {games.map((game, index) => (
                <GameCard item xs={6} sm={4} md={4} lg={3} key={index}>
                  <Box sx={{ position: 'relative', paddingTop: '75%' }}>
                    <GameImageWrapper>
                      <GameImage
                        src={game.image}
                        alt={game.name}
                        effect="blur"
                        placeholderSrc="https://via.placeholder.com/50x50.webp?text=Loading"
                        wrapperProps={{
                          style: {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                          },
                        }}
                      />
                    </GameImageWrapper>
                    <GameTitle>{game.name}</GameTitle>
                    {game.tag && <GameTag tagType={game.tag}>{game.tag}</GameTag>}
                  </Box>
                </GameCard>
              ))}
            </Grid>
          </StyledCard>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default HomeGrid