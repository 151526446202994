import * as React from "react";
import { Button, Divider, Grid, Typography, useTheme, styled } from "@mui/material";

const CardRoot = styled('div')(({ theme }) => ({
  background: '#e4e4e473',
  borderRadius: '8px',
  height: 'auto',
  boxShadow: '0 4px 6px rgba(151, 149, 149, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  textAlign: 'left',
  marginBottom: '10px',
  padding: '8px',
}));

const HeaderTypography = styled(Typography)(({ variant }) => ({
  fontSize: variant === 'title' ? '14px' : '10px',
}));

const SideTypography = styled(Typography)({
  fontSize: '12px',
  textAlign: 'center',
  fontWeight: '600',
  color: '#000',
});

const TeamTypography = styled(Typography)({
  fontSize: '12px',
  marginTop: '10px',
  fontWeight: 900,
});

const StyledButton = styled(Button)(({ theme, variant }) => ({
  background: theme.palette[variant === 'back' ? 'backButtonColor' : 'layButtonColor'],
  fontSize: '14px',
  color: '#000',
  textAlign: 'center',
  padding: '6px',
  borderRadius: '8px',
  marginTop: '4px',
  width: '95%',
}));

const BookmakerCard = () => {
  // const theme = useTheme();

  const marketData = {
    max: 100000,
    min: 100,
    playerData: [
      { team: "India", side: { lay: 1.55, back: 1.44 } },
      { team: "Pakistan", side: { lay: 1.56, back: 1.45 } },
    ],
  };

  const openPlacebet = (e) => {
  };

  return (
    <CardRoot>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <HeaderTypography variant="title">Bookmaker</HeaderTypography>
          <HeaderTypography>
            Min: {marketData.min} &nbsp; Max: {marketData.max}
          </HeaderTypography>
        </Grid>
        {['Back', 'Lay'].map((side) => (
          <Grid item xs={3} key={side}>
            <SideTypography>{side}</SideTypography>
          </Grid>
        ))}
      </Grid>

      <Divider />

      <Grid container>
        {marketData?.playerData?.map((player, index) => (
          <React.Fragment key={player.team}>
            <Grid item xs={6} style={{ alignItems: "center" }}>
              <TeamTypography>{player.team}</TeamTypography>
            </Grid>
            {['back', 'lay'].map((side) => (
              <Grid item xs={3} key={side}>
                <StyledButton
                  variant={side}
                  onClick={openPlacebet}
                >
                  {player.side[side]}
                </StyledButton>
              </Grid>
            ))}
            {index < marketData.playerData.length - 1 && <Divider style={{ width: '100%' }} />}
          </React.Fragment>
        ))}
      </Grid>
    </CardRoot>
  );
};

export default BookmakerCard;