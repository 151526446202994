
import React, {useMemo, useCallback} from 'react'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { format, isToday, differenceInDays } from 'date-fns'

const StyledCardContainer = styled('div')(({ theme, isEven }) => ({
  backgroundColor: isEven ? '#f2f1ed' : theme.palette.grey[300],
  fontWeight: 700,
  fontSize: '12px',
  marginBottom: '0.2em',
  minHeight: 40,
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: '63% 37%',
}))

const InfoContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: 4,
  gridTemplateColumns: '20% 70% 10%',
  padding: '0.5em 0.1em',
  alignItems: 'center',
  width: '100%',
}))

const DateContainer = styled('div')(({ theme, isEven }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  minWidth: '70px',
  padding: '4px 4px',
  borderRadius: '1px',
  backgroundColor: isEven ? '#f2f1ed' : theme.palette.grey[300],
}))

const DateText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: 1.2,
  color: theme.palette.text.primary,
}))

const TimeText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '11px',
  color: theme.palette.text.secondary,
}))

const CaptionContainer = styled('div')({
})

const ButtonGroupContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  overflowX: 'auto',
  gridTemplateColumns: 'repeat(6, 1fr)',
  width: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    overflowX: 'visible',
    flexWrap: 'wrap',
  },
  '&': {
    scrollBehavior: 'smooth',
    scrollSnapType: 'x mandatory',
  },
  '& > *:last-child': {
    scrollSnapAlign: 'end',
  },
  '& > *:first-child':{
    scrollSnapAlign:'start',
  }
}));

const ButtonItem = styled('div')(({ theme }) => ({
  minWidth: '40px',
  flex: '1 0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
  [theme.breakpoints.up('sm')]: {
    flex: '1 1 16.666%',
    minWidth: 'unset',
  },
}))

const LayButton = styled(ButtonItem)(({ theme }) => ({
  backgroundColor: theme.palette.layButtonColor,
  margin: '0.2em',
  borderRadius: '5px',
}))

const BackButton = styled(ButtonItem)(({ theme }) => ({
  backgroundColor: theme.palette.backButtonColor,
  margin: '0.2em',
  borderRadius: '5px',
}))

const LiveDiv = styled('div')(() => ({
  top: 0,
  left: 0,
  paddingRight: 10,
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'normal',
  },
}))
const EventListCard = React.memo(({ event, idx }) => {
  const navigate = useNavigate()

  const caption = useMemo(() => event?.match_name?.split(' v '), [event?.match_name])

  const handleClick = useCallback(() => {
    if(Boolean(sessionStorage.getItem('userDetails'))){
      navigate(`/sports/${event?.sport_name?.toLowerCase()}/${event?.market_id}/${event?.match_id}`)
    }
  }, [navigate, event?.sport_name, event?.market_id])

  const sampleBack = useMemo(() => event?.runner_json?.[0]?.ex.available_to_back || '', [event])
  const sampleLay = useMemo(() => event?.runner_json?.[0]?.ex.available_to_lay || '', [event])

  const formattedDate = useMemo(() => {
    const eventDate = new Date(event.date)
    const daysDifference = differenceInDays(eventDate, new Date())
    let dateString
  
    if (isToday(eventDate)) {
      dateString = 'Today'
    } else {
      dateString = format(eventDate, 'dd MMM') // Date in format "15 Jun"
    }
  
    const timeString = format(eventDate, 'HH:mm')
    return { date: dateString, time: timeString }
  }, [event.date])

  const isEven = useMemo(() => idx % 2 === 0, [idx])
  return (
    <StyledCardContainer onClick={handleClick} isEven={isEven}  >
      <InfoContainer>
      <DateContainer isEven={isEven} >
          <DateText>{formattedDate.date}</DateText>
          <TimeText>{formattedDate.time}</TimeText>
        </DateContainer>
        <CaptionContainer>
          <StyledTypography>{caption?.[0] || event.series_name}</StyledTypography>
          <StyledTypography>{caption?.[1] || ''}</StyledTypography>
        </CaptionContainer>
        {event.inplay && (
          <LiveDiv>
            <div className="liveIndicator"></div>
          </LiveDiv>
        )}
      </InfoContainer>
      <ButtonGroupContainer>
        <LayButton>
          <StyledTypography>{sampleLay?.[0]?.['price']}</StyledTypography>
        </LayButton>
        <BackButton>
          <StyledTypography>{sampleBack?.[0]?.['price']}</StyledTypography>
        </BackButton>
        <LayButton>
          <StyledTypography>{sampleLay?.[1]?.['price']}</StyledTypography>
        </LayButton>
        <BackButton>
          <StyledTypography>{sampleBack?.[1]?.['price']}</StyledTypography>
        </BackButton>
        <LayButton>
          <StyledTypography>{sampleLay?.[2]?.['price']}</StyledTypography>
        </LayButton>
        <BackButton>
          <StyledTypography>{sampleBack?.[2]?.['price']}</StyledTypography>
        </BackButton>
      </ButtonGroupContainer>
    </StyledCardContainer>
  )
})

EventListCard.displayName = 'EventListCard'

export default EventListCard