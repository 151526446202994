import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  availableStakes: [100, 200, 400],
  betSlip: [],
  user: null,
  modalState: {
    isOpen: false,
    type: null, // 'login' or 'signup'
  },
}

const appSlice = createSlice({
  name: 'appslice',
  initialState,
  reducers: {
    updateLoadingStatus: (state, action) => {
      state.isLoading = action.payload
    },
    updateAvailableStakes: (state, action) => {
      const { newValue, index } = action.payload
      if (!isNaN(newValue) && newValue > 0) {
        if (index === -1) {
          state.availableStakes.push(newValue)
        } else {
          state.availableStakes[index] = newValue
        }
      }
    },
    deleteAvailableStakes: (state, action) => {
      const { index } = action.payload
      state.availableStakes = state.availableStakes.filter((_, i) => i !== index)
    },
    updateBetSlip: (state, action) => {
      const { index, side, odds, market, betType } = action.payload
      const existingBetIndex = state.betSlip.findIndex(bet => bet.index === index && bet.side === side)

      if (existingBetIndex !== -1) {
        state.betSlip = []
      } else {
        const newBet = { index, side, odds, market, betType }
        state.betSlip = [newBet]
      }
    },
    deleteBetSlip: (state) => {
      state.betSlip = []
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    clearUser: (state) => {
      state.user = null
    },
    openModal: (state, action) => {
      state.modalState = { isOpen: true, type: action.payload }
    },
    closeModal: (state) => {
      state.modalState = { isOpen: false, type: null }
    },
  },
})

export const {
  updateAvailableStakes,
  updateLoadingStatus,
  deleteAvailableStakes,
  updateBetSlip,
  deleteBetSlip,
  setUser,
  clearUser,
  openModal,
  closeModal,
} = appSlice.actions

export default appSlice.reducer
