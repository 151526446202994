import * as React from "react";
import "./0_Common.css";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const EventCard = ({ item }) => {
  const navigate = useNavigate();

  const handleNavigateToEvent = (e) => {
    navigate(`/landingPage/${item?.event?.gameType?.toLowerCase()}/${item?.event?.id}`);
  };

  const caption =
    item?.event?.teams?.[0]?.name +
    " " +
    "v/s" +
    " " +
    item?.event?.teams?.[1]?.name;

  const eventDateTime = new Date(item?.event?.openDate);
  const eventTime = eventDateTime.toDateString() + eventDateTime?.toTimeString()
  return (
    <div className="card" style={{ background: item.imageUrl, minHeight: 150 }}>
      <div className="eventHead">
        <Typography sx={{ fontSize: "12px" }} fontWeight="bold">
          {caption}
          <Typography fontSize={10}>{eventTime}</Typography>
        </Typography>
        {item.event.isLive && <div className="liveIndicator"></div>}
      </div>

      <div className="eventBody">
        {item?.event?.teams?.map((i) => {
          return (
            <div>
              <img
                alt={i.name}
                className="iconContainer"
                height={50}
                width={50}
                src={'https://img.freepik.com/free-vector/illustration-gallery-icon_53876-27002.jpg'}
                onClick={handleNavigateToEvent}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(EventCard);
