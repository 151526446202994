import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

function getFormattedDate(date) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  let formattedDate = date.toLocaleDateString("en-US", options);

  // Add ordinal indicators ('st', 'nd', 'rd', 'th') to the day
  const day = date.getDate();
  let ordinalIndicator;
  if (day > 3 && day < 21) ordinalIndicator = "th";
  else if (day % 10 === 1) ordinalIndicator = "st";
  else if (day % 10 === 2) ordinalIndicator = "nd";
  else if (day % 10 === 3) ordinalIndicator = "rd";
  else ordinalIndicator = "th";

  return formattedDate.replace(new RegExp(day), `${day}${ordinalIndicator}`);
}

function DateComponent({ isDesktop }) {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formattedDate = getFormattedDate(currentTime);
  const formattedTime = currentTime.toLocaleTimeString("en-US");
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div
      style={{
       display:'flex',
       gap:2,
       justifyContent:'center',
       alignItems:'center',
    
      }}
    >
      <Typography
        fontSize={12}
        style={{ color: '#fff'}}
      >
        {formattedDate}
      </Typography>
      <Typography
        fontSize={12}
        style={{ color: '#fff'}}
      >
        {formattedTime}
      </Typography>
      <Typography
        fontSize={12}
        style={{ color: '#fff'}}
      >
        {timeZone}
      </Typography>
      <Divider />
    </div>
  );
}

export default DateComponent;
