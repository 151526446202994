// Safely access environment variables with fallbacks
const REACT_APP_BASE_URL = window.ENV_CONFIG?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || '';
const REACT_APP_WEBSOCKET_HOST = window.ENV_CONFIG?.REACT_APP_WEBSOCKET_HOST || process.env.REACT_APP_WEBSOCKET_HOST || '';

export const ActiveMatches = `${REACT_APP_BASE_URL}/match/active`;
export const loginUrl = `${REACT_APP_BASE_URL}/user/login`;
export const otpUrl = `${REACT_APP_BASE_URL}/user/send-otp`;
export const betPlaceUrl = `${REACT_APP_BASE_URL}/bet/market-bet`;
export const betPlaceFancyUrl = `${REACT_APP_BASE_URL}/bet/fancy-bet`;
export const balanceURL = `${REACT_APP_BASE_URL}/user/balance`;
export const AccountStatementUrl = `${REACT_APP_BASE_URL}/account/getStatement`
export const BetHistoryUrl = `${REACT_APP_BASE_URL}/bet/bet-history`
export const UpdatePasswordUrl = `${REACT_APP_BASE_URL}/user/update-password`
export const BannerListUrl = `${REACT_APP_BASE_URL}/banner/list`
export const OpenBetsUrl = `${REACT_APP_BASE_URL    }/bet/bet-history`
export const CreateDepositRequestUrl = `${REACT_APP_BASE_URL}/deposit-withdrawal-request/create`
export const ParentListUrl = `${REACT_APP_BASE_URL}/user-account/parent-list`
export const UserAccountListUrl = `${REACT_APP_BASE_URL}/user-account/list`
export const UserAccountCreateUrl = `${REACT_APP_BASE_URL}/user-account/create`
export const WithdrawalRequestUrl = `${REACT_APP_BASE_URL}/deposit-withdrawal-request/create`
export const UserAccountDeleteUrl = `${REACT_APP_BASE_URL}/user-account/delete`
export const UserSettingUrl = `${REACT_APP_BASE_URL}/user/user-setting`
export const ProfitLossUrl = `${REACT_APP_BASE_URL}/account/get-pl`

//Socket
export const webSocketURL = `${REACT_APP_WEBSOCKET_HOST}/api/socket`;