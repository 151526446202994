import { Grid } from "@mui/material";
import * as React from "react";
import MatchOddsCard from "../0_Common/MatchOddsCard";
import FancyCard from "../0_Common/FancyCard";

const CricketEvent = ({ eventData }) => {

  return (
    <Grid container spacing={1}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <React.Suspense fallback={<div>loading matchodds</div>}>
          <MatchOddsCard eventData={eventData} />
        </React.Suspense>

      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

        <React.Suspense fallback={<div>Loading from Suspense</div>}>
          <FancyCard />
        </ React.Suspense>
      </Grid>
    </Grid>
  );
};

export default CricketEvent;
