import { useState, useCallback } from 'react';

const useApiFetch = (url, options) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log(result);
      if (!response.ok) {
        throw new Error(`${result.message}`);
      }

      setData(result);
    } catch (err) {
 console.log('err use api',err)
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return { data, loading, error, fetchData };
};

export default useApiFetch;
