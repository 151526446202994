import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OpenBetsUrl } from '../../constants';
import useApiFetch from '../../hooks/useApiFetch';

export function OpenBetsPage() {
  const theme = useTheme();
  const [expanded, setExpanded] = useState('unmatched');
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails') || '{}');

  const apiOptions = {
    method: 'POST',
    headers: {
      'accept': 'application/json, text/plain, */*',
      'authorization': userDetails.token || '',
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      user_id: userDetails.id,
      result_id: "",
      is_fancy: "",
      is_back: "",
      win_status: "",
      user_name: "",
      page: 1,
      total_items: 0,
      items: 50
    }),
    credentials: 'include'
  };

  const { data, isLoading, error, fetchData } = useApiFetch(OpenBetsUrl, apiOptions);

  const fetchBets = useCallback(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchBets();
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function renderBetsTable(bets) {
    if (!bets || bets.length === 0) {
      return <Typography sx={{ color: theme.palette.primary.main }}>No bets to display</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Event Name</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Selection</TableCell>
              <TableCell>Odds</TableCell>
              <TableCell>Stake</TableCell>
              <TableCell>P/L</TableCell>
              <TableCell>Placed At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bets.map((bet) => (
              <TableRow key={bet.id}>
                <TableCell>{bet.match_name}</TableCell>
                <TableCell>{bet.market_name}</TableCell>
                <TableCell>{bet.selection_name}</TableCell>
                <TableCell>{bet.odds}</TableCell>
                <TableCell>{parseFloat(bet.stack).toFixed(2)}</TableCell>
                <TableCell>{parseFloat(bet.p_l).toFixed(2)}</TableCell>
                <TableCell>{new Date(bet.created_at).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const unmatchedBets = data?.data?.data?.filter(bet => bet.is_matched === '0');
  const matchedBets = data?.data?.data?.filter(bet => bet.is_matched === '1');

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh', padding: 2 }}>
      <Typography variant="h6" sx={{ mb: 2, color: theme.palette.text.primary }}>Open Bets</Typography>
      
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          Error: {error.message}
        </Typography>
      )}

      {isLoading ? (
        <Skeleton variant="rectangular" height={200} />
      ) : (
        <>
          <Accordion 
            expanded={expanded === 'unmatched'} 
            onChange={handleAccordionChange('unmatched')}
            sx={{ mb: 2, bgcolor: theme.palette.background.paper }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="unmatched-bets-content"
              id="unmatched-bets-header"
              sx={{ bgcolor: theme.palette.buttonSecondaryColor,color:'white' }}
            >
              <Typography>Unmatched Bets</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderBetsTable(unmatchedBets)}
            </AccordionDetails>
          </Accordion>

          <Accordion 
            expanded={expanded === 'matched'} 
            onChange={handleAccordionChange('matched')}
            sx={{ bgcolor: theme.palette.background.paper }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="matched-bets-content"
              id="matched-bets-header"
              sx={{ bgcolor: theme.palette.buttonSecondaryColor,color:'white'}}
            >
              <Typography >Matched Bets</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderBetsTable(matchedBets)}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
}

export default OpenBetsPage;