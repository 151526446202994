import React from "react";

export function TennisIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="white"
        d="M365.6 31c-6.6 0-13.2.6-19.7 1.59c-34.5 5.44-66.5 23.14-88.3 44.96c-28.8 28.85-49.6 70.85-58.4 111.65c-1.6 6.7-2.6 13.6-3.5 20.4L162.2 334l15.8 15.8l124.3-33.5c6.8-.9 13.7-2 20.5-3.5c40.8-8.8 82.8-29.6 111.6-58.4c21.9-21.9 39.6-53.9 45-88.4c5.2-34.5-2.4-72.3-31.9-101.71c-23.8-23.93-53.1-33.44-81.9-33.29m.1 19.29c24.3-.28 47.9 7.49 68.2 27.71c25 24.9 31 55.2 26.3 85c-4.6 29.9-20.7 58.8-39.5 77.6c-25.4 25.4-64.7 45.2-102 53.2c-37.2 8.2-71.2 3.8-87.8-12.9c-16.5-16.5-20.9-50.4-12.8-87.7c8.1-37.2 27.8-76.6 53.3-101.94c18.7-18.78 47.7-34.84 77.6-39.55c5.5-.88 11.2-1.36 16.7-1.42m-166 214.81c3.7 11.3 9.3 21.5 17.5 29.5c8.1 8.2 18.2 14 29.5 17.6l-63 16zm-50.3 83.6L50 448.2l-5.25-5.1l-13.71 13.7L55.41 481l13.7-13.6l-5.41-5.4l99.5-99.6zm211 45.1c-19.5 0-35.6 16-35.6 35.5s16.1 35.5 35.6 35.5c19.4 0 35.5-16 35.5-35.5s-16.1-35.5-35.5-35.5"
      ></path>
    </svg>
  );
}
